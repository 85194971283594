import React, { useState } from "react";
import ShareDialog from './ShareDialog'
import {error_format} from 'utils';
import axios from 'axios';
import { I18n } from 'I18n.js';
import {
  Table,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'

const ShareControlDialog = ({ ...props }) => {
  const { id, alias } = props;
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');
  const [users, setUsers] = useState([]);

  const toggle = () => {
    if (!show) { getlist(); }
    setShow(!show);
    setMsg('');
  }

  const getlist = () =>
  {
    axios.get('/thing/buddies', { params: {'id': id }})
    .then((res) => {
      const users = JSON.parse(res.request.response);
      setUsers(users.buddies);
    })
    .catch((error) => { setMsg(error_format(error)); })
  }

  const revoke = (id, email) =>
  {
    axios.post('/thing/revoke', {'id': id, 'user': email})
    .then(() => {getlist();})
    .catch((error) => { setMsg(error_format(error)); })
  }

  const title = I18n.get('Shared control');
  const body = <Table responsive>
                  <thead>
                    <tr>
                      <th>{I18n.get('Name')}</th>
                      <th className="text-right">{I18n.get('Access')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users && users.map(function(email, i)
                    { 
                      return (<tr key={i} >
                              <td>{email}</td>
                              <td className="td-actions text-right">
                                <Button simple bsStyle="danger" bsSize="lg" onClick={() => {revoke(id, email);}}>
                                  <i className="fa fa-times" />
                                </Button>
                                </td> 
                              </tr>)
                    })
                  }
                  </tbody>
                </Table>
  
  const custom_footer = <Modal.Footer>
                          <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
                          <ShareDialog id={id} alias={alias} update={getlist} />
                        </Modal.Footer>

  return (
    <>
    <OverlayTrigger placement="top" overlay={<Tooltip id="share">{I18n.get('Sharing')}</Tooltip>}>
        <Button simple bsStyle="success" style={{padding: '16px 24px'}} bsSize="lg" onClick={toggle} >
          <i className="pe-7s-next-2" />
        </Button>
    </OverlayTrigger>

    <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} msg={msg} footer={custom_footer}/>
    </>
  );
}


export default ShareControlDialog;