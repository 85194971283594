import React, { useState } from "react";
import axios from 'axios';
import { getUserInfo, MQTT_SERVER, setSettings } from "server.js"
//import { useAuth0 } from "./react-auth0-spa";
import { boundUserInfo, boundApiConnected, boundApiCardsThings, boundMqttHead, store } from 'redux-utils';

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import BaseCard from "components/BaseCard.jsx";
import Button from "components/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox.jsx";
import { I18n } from 'I18n.js';
import {URL_PRICES, URL_SUPPORT, URL_DOCUMENTS, locale} from 'server';
import { setApiToken } from "server.js"


var mqtt = require('mqtt');

export const subscribe = (userInfo, props) =>
{ 
  axios.get('/auth').then((res) => { 
    const options = {
      port: 8884,
      host: MQTT_SERVER,
      path: "/mqtt",
      clientId: res.data.id,
      username: res.data.id,
      password: res.data.token,
      protocolVersion: 4,
      protocol: 'wss',
      reconnectPeriod: 300000,
    }
    
    setTimeout(function(){
    const client = mqtt.connect(options);
    client.on('connect', function () {
      //console.log('MQTT connect');
      client.subscribe('web/' + userInfo.user.email + '/state', function (err) {
        if (!err) {
          client.on('message', function (topic, message) {
            const mqtt_card = JSON.parse(message.toString());
            //console.log('Got MQTT', mqtt_card);
            const state = store.getState();
            //console.log(state);
            const cards = state.cards_things.cards.map(function(card)
            {
              if (card.id === mqtt_card.id)
              {
                
                if ((card.story) && (card.story.length > 63))
                {
                  card.story.shift();
                }
                card.story.push(mqtt_card.current);
                card.current = mqtt_card.current;
              }
            return(card);
            });
            //boundApiCards(cards);

            const things = state.cards_things.things.map(function(thing)
            {
              if (thing.id === mqtt_card.id)
              {
                thing.ts = mqtt_card.current.ts;
              }
            return(thing);
            }, this);

            //boundApiThings(things);
            boundApiCardsThings({cards: cards, things: things});
          })
        }else
        {console.log(err);}
      })
    })
    boundMqttHead({client:client, topic : 'web/' + userInfo.user.email + '/update'});  
    }, 80000);

    //this.setState({mqtt: {client:client, topic: 'web/'+user.email+'/update'}});
  })
}

const ExternalApi = ({ component: Component, ...props }) => {
    const [connected, setConnected] = useState(false);
    //const { getTokenSilently } = useAuth0();
    const [showLogin, setShowLogin] = useState(false);
    const [terms, setTerms] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const callApi = async () => {
        //console.log('Calling');
        //const token = await getTokenSilently();
        if (true)
        {
          //console.log('Got token',token);
          //setApiToken(token);
          const userInfo = await getUserInfo();
          //console.log(userInfo);
          if (userInfo !== null)
          {
              //console.log(userInfo);
              setSettings(userInfo.user.settings.lang, userInfo.user.settings.nav);
              boundUserInfo(userInfo);              
              boundApiCardsThings({cards: userInfo.cards, things: userInfo.things});

              subscribe(userInfo, props);
              setConnected(true);
              boundApiConnected(true);
              setShowLogin(false);
          } else {
              setShowLogin(true);
          }
        }
    };

    if (connected)
    {
      return (
          <>
              <Component {...props} />
          </>)
    }else
    {
      //console.log('Call API');  
      //callApi();

     
      if (showLogin == false) {
        callApi();
      } else {
        const lang = locale.startsWith('ru')?'ru':'en';
        I18n.setlang(lang);
      }
     

      return (
        <>
        {showLogin &&
        <Grid>
          <Row>
            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
              <br/>
              <br/>
              <br/>
              <form>
                <BaseCard
                  textCenter
                  title={I18n.get('LogIn')}
                  content={
                    <div>
                      <FormGroup>
                        <ControlLabel>Email</ControlLabel>
                        <FormControl placeholder="" type="email" defaultValue={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>{I18n.get('Password')}</ControlLabel>
                        <FormControl placeholder="" type="password" autoComplete="off" defaultValue={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                      </FormGroup>
                      <FormGroup>
                        <Checkbox number="1" label={<div> {I18n.get('I agree with ')} <a href={URL_DOCUMENTS} rel="noopener noreferrer" target="_blank">{I18n.get('privacy & terms')}</a></div>} checked={terms} onChange={() => {setTerms(!terms)}} />
                      </FormGroup>
                    </div>
                  }
                  legend={
                    terms ?
                   
                      <Button bsStyle="info" fill wd onClick={()=> {
                        setApiToken(email, password);
                        callApi();
                      }}>
                      {I18n.get('LogIn')}
                      </Button>
                      :
                      <Button bsStyle="default" fill wd >
                        {I18n.get('LogIn')}
                      </Button>
                  }
                  ftTextCenter
                />
              </form>
            </Col>
          </Row>
      </Grid>}
      </>
      )
    }
    
  };

export default ExternalApi;