import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { I18n } from 'I18n.js';

const ReadMsgDialog = ({ ...props }) => {
  const { hide, msg } = props;
  return (
      <div className="register-page">
        <ReactBSAlert
              style={{ display: "block", marginTop: "-100px" }}
              title={I18n.get('Message')}
              onConfirm={() => hide()}
              onCancel={() => hide()}
              confirmBtnBsStyle="info"
              showConfirm={false}
            >
              <h5>{msg}</h5>
            </ReactBSAlert>
      </div>
  );
}

export default ReadMsgDialog;
