import React from 'react';
import { I18n } from 'I18n.js';
import {
  Modal,
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";

const BaseDialog = ({ ...props }) => {
  const {show, onhide_cb, title, body, msg, footer, apply_cb, size } = props;

  const default_footer =  <Modal.Footer>
                            <Button simple onClick={onhide_cb}> {I18n.get('Close')} </Button>
                            <Button simple bsStyle="success" onClick={apply_cb}> {I18n.get('Apply')} </Button>
                          </Modal.Footer>

  return (
    <>
      <Modal bsSize={size} show={show} onHide={onhide_cb}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
          {msg &&
                <blockquote className="blockquote blockquote-danger">
                    <p className="text-danger" align="center">{msg}</p>
                </blockquote>
              }
        </Modal.Body>
        {footer?footer:default_footer}
      </Modal>
    </>
  );
}

export default BaseDialog;