import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "components/CustomButton.jsx";
import { I18n } from 'I18n.js';
import { connect } from 'react-redux';

const WatchDogControl = ({ ...props }) =>  {
    const send = (btn) =>
    {
        const state = (btn === 1)?{rst:1}: {pwr:1};
        props.mqtt.client.publish(props.mqtt.topic, JSON.stringify({id: props.id, state: state}));
    }

    if (props.mqtt)
    {
      return (
          <div>
            <OverlayTrigger placement="top" overlay={<Tooltip id="power">{I18n.get('Reset')}</Tooltip>}>
                  <Button bsSize="xs" simple fill round bsStyle="warning" onClick={() => send(1)}>
                  <i className="pe-7s-gleam" />
                  </Button>
                </OverlayTrigger>
                {" "}
                <OverlayTrigger placement="top" overlay={<Tooltip id="power">{I18n.get('Hard reset')}</Tooltip>}>
                  <Button bsSize="xs" simple fill round bsStyle="danger" onClick={() => send(2)}>
                  <i className="pe-7s-power" />
                  </Button>
                </OverlayTrigger>
          </div>
      )
    } else
    {
        return (<div></div>);
    }
};

const mapStateToProps = function(store) {
  return {
    mqtt: store.mqtt,
  };
}

export default connect(mapStateToProps)(WatchDogControl);

