export const I18n = {
        lang: 'en',
        dict: {'en': {}},
        getlang() {return this.lang},
        setlang(lang) { if (this.dict.hasOwnProperty(lang)) { this.lang = lang;  } return this.lang},
        setdict(dict) {this.dict = dict; this.dict.en = {}},
        get(text)   { return this.dict[this.lang].hasOwnProperty(text)? this.dict[this.lang][text]: text }
     };

const dict = {
    'ru': {
//Sidebar        
        'Dashboard': 'Панель',
        'Things': 'Устройства',
        'Office': 'Офис',
        'Profile': 'Профиль',
        'Connect': 'Подключить',
//Card
        'activity': 'активность',
        'Activity': 'Активность',
        ' never.': ' никогда.',
        'New card': 'Новая карта',
        'Press + to Add a new card': 'Нажмите + для создания карточки',
        'person': 'человек',
        'Usage': 'Использовано',
        'total ': 'всего ',
        'Pause': 'Пауза',
        'Reset': 'Перезагрузка',
        'Hard reset': 'Жёст. перезагрузка',
        'devices ': 'устройств ',
        'arrived': 'пришёл',
        'left': 'ушёл',
        'last': 'последнее',
        'inside': 'на месте',
        'outside': 'отсутствуют',
//Dialogs
        'Add the device': 'Добавить устройство',
        'Alias': 'Имя',
        'Thing': 'Устройство',
        'any name': 'любое имя',
        'Apply': 'Применить',
        'Close': 'Закрыть',
        'Delete': 'Удалить',
        'Are you sure? You can add it again later.': 'Вы уверены? Вы сможете добавить его снова позже.',
        'Disconnect the shared device': 'Отосединить расшареное устройство',
        'Are you sure?': 'Вы уверены?',
        'History': 'История',
        'In the office ': 'В офисе ',
        'Show history (days)': 'Показать историю (дней)',
        'Export': 'Экспорт',
        'Rename': 'Переименовать',
        'New name': 'Новое имя',
        'any unique name': 'любое уникальное имя',
        'Disconnect': 'Отсоединить',
        'Share': 'Расшарить',
        'Shared': 'Расшарен',
        "Receiver's user e-mail": 'E-mail получателя',
        'Task': 'Задача',
        'Under development': 'В разработке',
        'Shared control': 'Контроль доступа',
        'Add': 'Добавить',
        'Now': 'Сейчас',
        'Users who can access the device': 'Пользователи, имеющие доступ к устройству',
        'Access': 'Доступ',
        'Disable': 'Отключить',
        'Add new': 'Добавить новых',
        'Nobody': 'Никого',
        'Copy this serial to your virtual device': 'Скопируйте этот номер в ваше виртуальное устройство',
        'You are adding a new device': 'Вы добавляете новое устройство',
        'Create': 'Создать',
        'Message': 'Сообщение',
        'Error': 'Ошибка',
        'To the dashboard': 'На панель',
        'No data, try to change a period': 'Нет данных, попробуйте изменить период',

        'Enter any apropriate name': 'Введите любое удобное имя',
        'The card is added': 'Карта добавлена',
        'Nobody has shared with you yet': 'Никто пока не поделился с вами',
        'Email is confirmed': 'Email подтверждён',
        'Your Email is confirmed. Thanks!': 'Ваш Email подтверждён. Спасибо!',
        
//Things  
        'Total': 'Всего',
        'Real devices': 'Реальные устройства',
        'Virtual devices': 'Виртуальные устройства',
        'Things List': 'Список устройств',
        'Type': 'Тип',
        'Actions': 'Действия',
        'never': 'никогда',
        'Shared with me': 'Расшаренные принятые',
        'get serial': 'получить серийный номер',
        'Summary': 'Суммарно',
        'Used': 'Использовано',
        'Available': 'Доступно',
        'Restore': 'Восстановить',
        'BLOCKED': 'ЗАБЛОКИРОВАНО',
        'By you': 'Вами',
        'For you': 'Вам',
        'proposed': 'предложено',
        'from': 'из',
        'Proposed': 'Предложенные',
        'Shared ': 'Расшаренные ',
        'inactive': 'неактивные',
        'Control center': 'Контрольный центр',
        'added': 'добавлен',
        'Shared by': 'Расшарил',
        'Add a device': 'Добавить устройство',
        'Register new': 'Зарегистрировать новое',
        'Share a device': 'Расшарить устройство',
        'Conneсt shared': 'Подключить расшареное',
        'Code': 'Код',
        'Temperature sensor': 'Температурный датчик',
        'Temprature/Humidity sensor': 'Датчик температуры-влажности',
        'CO2 sensor': 'Датчик CO2',
        'WatchDog': 'Сторожевой таймер',
        'PC gateway': 'ПК шлюз',
        'Sharing': 'Расшарить',
        'Notifier': 'Уведомления',
        'Fingerprint sensor': 'Биометрический датчик',

//Office
        'Create Office Control card first.': 'Создайте предварительно карточку Office Control.',
        'Card': 'Карточка',
        'Get report': 'Создать отчёт',
        'From': 'От',
        'To': 'До',
        'Submit': 'Отправить',
        'Name': 'Имя',
        'Report': 'Отчёт',
        'Users': 'Пользователей',
        'Time': 'Время',
        'Action': 'Действие',
        'hours': 'часов',
        'Details': 'Детально',
        'No': 'Нет',
        'Divide per person': 'Разделить по людям',
        'Options': 'Настройки',
        'y': 'л', 
        'mo': 'мес', 
        'h': 'ч', 
        'm': 'м', 
        's': 'с', 
        'violation': 'нарушение',
//Profile
        'Success': 'Успешно',
        'Settings': 'Настройки',
        'Language': 'Язык',
        'Sidebar': 'Боковая панель',
        'Current plan': 'Текущий план',
        'Next plan': 'Следующий план',
        'Extend a subscription': 'Продлить подписку',
        'Upgrade': 'Улучшить',
        'Time left': 'Осталось',
        'forever': 'вечность',
        'Office Control Cards': 'Карты Office Control',
        'Cards': 'Карточки',
        'Static' : 'Статичная',
        'Hide': 'Всплывающая',
        'Auto': 'Авто',
        'Plan ends': 'Окончание тарифа',
        'confirmed': 'подтверждено',
        'unconfirmed': 'неподтверждено',
        'Telegram code from @unitx_notifier_bot': 'Telegram код от @unitx_notifier_bot',
        'Send': 'Отправить',
        'E-mail for task notifications': 'E-mail для уведомлений от задач',
        'Notifications settings': 'Настройки уведомлений',
        'Plan': 'План',
        'Account': 'Аккаунт',
        'Balance': 'Баланс',
        'Recharge': 'Пополнить',
        'Change the plan': 'Изменить тарифный план',
        'Change': 'Изменить',
        'TimeZone': 'Часовой пояс',
        'Mail code': 'Mail код',
        'blocked': 'заблокировано',
        'days': 'дней',
        'About plans': 'О тарифах',
        'Invoice': 'Счёт',
        'INN': "ИНН",
        'The invoice is downloaded': 'Счёт загружен',
        'Generate': 'Сгенерировать',
        'Tomorrow plan': 'Завтрашний план',
        'The plan will be updated at 03:00 MSK': 'План будет обновлён в 03:00 по Москве',
        'from ': 'из ',
        'For companies': 'Для компаний',
//Register
        'Download CREDENTIALS': 'Скачать сертификаты',
        'Create any name for your device': 'Выберите удобное имя своему устройству',
        'Register': 'Регистрация',
        'Register a new device': 'Регистрация нового устройства',
        'API ver.': 'Вер. API',
        'S/N': 'Номер',
        'Save the following Reg.ID': 'Сохраните данный Reg.ID',
        'and download the credentials (a certificate and a key) for your device. They will be lost once you leave this page.': 'и загрузите сертификаты для вашего устройства. Эту страницу нельзя будет вызвать позже.',
        'Please enter at least 1 characters': 'Введите хотя бы 1 символ, пожалуйста',
        'Download certificate': 'Скачать сертификат',
        'Download key': 'Скачать ключ',
        'New device': 'Новое устройство',
        'Enter a device code': 'Введите код устройства',
//UserNavBar
        'LogOut': 'Выход',
        'Notifications': 'Уведомления',
        'No notifications': 'Нет уведомлений',
        'More': 'Ещё',
        'Help Center': 'Помощь',
        'Documents': 'Документы',
        'Manual': 'Инструкция',
        'Mark all as read': 'Всё как прочитанное',
//Login
        'Connect to the IoT world': 'Подключитесь к миру IoT',
        'Customizable dashboard for your IoT projects': 'Настраиваемая панель управления для ваших проектов "Интернета вещей"',
        'Monitor and control you devices in real time': 'Следите и управляйте своими устройствами в реальном времени',
        'Create your own logic and make the devices communicate': 'Создавайте свою логику взаимодействия устройств',
        'Use cloud for free': 'Подключитесь бесплатно',
        'Keep devices connected': 'Поддерживайте связь с устройствами',
        'Automation': 'Автоматизация',
        'Your registration data is protected by Auth0 service': 'Ваши регистрационные данные защищены сервисом Auth0',

//AuthNavBar
        'LogIn': 'Войти',
        'Pricing': 'Тарифы',
        'Blog': 'Блог',
        'Devices': 'Устройства',
        'Support': 'Поддержка',
//Content
        'Free': 'Старт',
        'Personal': 'Персональный',
        'Basic': 'Базовый',
        'Professional': 'Продвинутый',

        '0 EUR/mo': '0 руб/мес',
        '1 EUR/mo': '80 руб/мес',
        '2 EUR/mo': '150 руб/мес',
        '10 EUR/mo': '700 руб/мес',
        '20 EUR/mo': '1400 руб/мес',
        '30 EUR/mo': '2100 руб/мес',
        '20 employees': '20 работников',
        '50 employees': '50 работников',
        '100 employees': '100 работников',

        'Time accounting': 'Подсчёт времени',
        'Sensors': 'Датчики',

        'Node-Red Editor': 'Редактор Node-Red',
        'Personal support': 'Персональная поддержка',
        'Individual': 'Индивидуальный',
        'Call us': 'Позвоните нам',
        'As you need': 'Сколько требуется',
        'Custom projects': 'Адаптированные проекты',
        'Individual solutions': 'Индивидуальный решения',
        'Virtual Things': 'Виртуальные устройства',

//Auth0
        'Verification letter was sent for you': 'Письмо с запросом было отправлено вам на почту',
        'Please verify your email before logging in.': 'Пожалуйста, подтвердите email для входа.',
        'Verification pending': 'Ожидание подтверждения',
//LockPlan
        'Your plan was expired': 'Ваш тарифный план закончился',
        'Go to the shop!': 'В магазин!',
        'Downgrade': 'Понизить',
        'Your plan was expired: 2 days later it will be downgraded to the Free plan. Over cards & things will be removed.': 'Ваш тарифный план закончился и через 2 дня будет переведён на "Старт". Все лишние карточки и устройства будут удалены.',
        'You can pay or change plan to Free immediately': 'Вы можете оплатить тариф или перейти на тариф "Старт" сейчас.',
        'Your plan was changed!': 'Ваш тарифный план изменён!',
        'Your plan is Free': 'Ваш тарифный план - "Старт"',
//Footer
        'Licenses': 'Лицензионное соглашение',
        'News': 'Новости',
        'made with': 'сделано с',
        'by': 'в',
//RegDoc
        'Add a new device': 'Добавить новое устройство',
        'Paste device RegDoc': 'Вставьте RegDoc от устройства',
        'Paste': 'Вставить',
        'Place RegDoc text here': 'Вставьте RegDoc-текст',
        'You can register new devices here.': `Для подключения устройства нужно вставить в окно RegDoc от устройства, а полученный ответ вставить в форму регистрации в устройстве.`,
        'Format error': 'Ошибка формата',
        'The device is registered!': 'Устройство зарегистрировано!',
        'The data is copied': 'Данные скопированы',
        'To finish the connection process, copy the answer to the device': 'Для завершения регистрации, скопируйте ответ в устройство',
//TaskDialog
        'Tasks are available for Basic plan or better': 'Задачи доступны для тарифных планов "Персональный" и выше',
        'Variable': 'Переменная',
        'Сondition': 'Условие',
        'Value': 'Значение',
        'Comparison threshold': 'Порог сравнения',
        'Rate limit, minutes': 'Срабатывать не чаще, минут',
        'Destination': 'Получатель',
        'Clear': 'Очистить',
        'Task updated!': 'Задача обновлена!',
        'Task cleared!': 'Задача очищена',
        'any': 'любое',
        'offline': 'оффлайн',
        'online': 'онлайн',
//AutoReg
        'We have got a correct request for registration. The device had been added to you list.': 'Регистрационные данные устройства получены. Устройство добавлено в список.',
        'To finish registration send the client certificate to your device.': 'Для завершения регистрации клиентский серитфикат должен быть загружен в устройство.',
        'Send certificate': 'Передать сертификат',
//
        'Empty now' : 'Пока пусто',
        'Fail' : 'Неудачно',
        'Your payement is failed :(' : 'Ваша оплата не прошла :(',
        'Your payement is done. Thanks! :)' : 'Ваша оплата завершена. Спасибо! :)',
        'Payement is done': 'Платёж совершён',
        'Password': 'Пароль',
        'I agree with privacy & terms': 'Я согласен с соглашением и политикой конфиденциальности',
        'I agree with ': 'Я согласен с',
        'privacy & terms':'соглашением и политикой конфиденциальности',

    }
};

I18n.setdict(dict);
