import React, { useState, useEffect } from "react";

import Switch from "react-bootstrap-switch";
import OfficeControlNowDialog from '../Dialogs/OfficeControlNowDialog';
import { hexToRGB, colors, error_format } from 'utils';
import { I18n } from 'I18n.js';
import { labels_prepare, LineBody, BarBody, PieBody } from "./body";
import axios from 'axios';
import { toast } from 'react-toastify';
import WatchDogControl from './controls';


const ODTemp = ({ ...props }) => {
  const values_prepare = (story, attrs, color) => {
    const data_t = [];
    const data_h = [];
    story.forEach(item=> {
      data_t.push(item.state.t);
      if (item.state.h !== undefined){data_h.push(item.state.h);}
    });
    const res = [{key:'t', color: hexToRGB(color.t), bgcolor: hexToRGB(color.t, 0.4), data: data_t}];
    
    if (data_h.length>0)
    {
      res.push({key:'h',  color:  hexToRGB(color.h), bgcolor:  hexToRGB(color.h, 0.4), data: data_h });
    }
    return res;

  }

  return LineBody( {labels_prepare, values_prepare,  ...props} );
}


const UnitXC = ({ ...props }) => {
  const values_prepare = (story, attrs, color) => {
    const data = story.map(function(element)
    {
      return element.state.c;
    }, this);

    return [{key:'ppm', color: hexToRGB(color.c), bgcolor: hexToRGB(color.c, 0.4), data: data}];
  }
  return LineBody( {labels_prepare, values_prepare,  ...props} );
}

const GateWay = ({ ...props }) => {
  const values_prepare = (story, attrs, color) => {
    const data = story.map(function(element)
    {
      return element.state.count;
    }, this);

    return [{key:'count', color: hexToRGB(color.c), bgcolor: hexToRGB(color.c, 0.4), data: data}];
  }
  return LineBody( {labels_prepare, values_prepare,  ...props} );
}


const Mock = ({ ...props }) => {
  const values_prepare = (story, attrs) =>
  {    
   const data = story.map(function(element)
    {
      return element.state.switch;
    }, this);
    return [{key:'s', color: 'rgba(86, 158, 224, 1)', bgcolor: 'rgba(86, 158, 224, 0.8)', data: data, stepped:true}]
  }
  return LineBody( {labels_prepare, values_prepare,  ...props} );
}

  class MockControl extends React.Component {
    handleSwitch(elem, state) {
      const payload = {
        id: this.props.id,
        state: {switch: state?1:0, ping: 1}
      }
      this.props.imqtt.client.publish(this.props.imqtt.topic, JSON.stringify(payload));
    }

    render() {
      if (this.props.istate !== 'n/a')
      {
      return (       
        <div>
          <Switch value={this.props.state?true:false} onColor="default" offColor="default" onChange={(el, state) => this.handleSwitch(el, state)} />
        </div> 
      );
      }else
      {
        return (<div></div>);
      }
    }
  }
  

  const WatchDog = ({ ...props }) => {
    const values_prepare = (story, attrs) =>
    {
      const data = [];
      const ch3 = [];
      story.forEach(item=> {
        data.push((item.state.flags & 0x1)+1);
        ch3.push(item.state.ch3);
      });
      const res = [{key:'flags', color: hexToRGB(colors[3]), bgcolor: hexToRGB(colors[3], 0.4), data: data, yAxisID: 'flags'},
                   {key:'ch3',  color: hexToRGB(colors[1]), bgcolor: hexToRGB(colors[1], 0.4), data: ch3, yAxisID: 'ch3', type: 'line'}
                ];
      return res;
    }

    return BarBody( {labels_prepare, values_prepare,  ...props} );
  }
  
 
  
const Office = ({ ...props }) => {
      const { story, id } = props;
      const [attrs, setAttrs] = useState({});

      var idx;
      var inside = 0;
      var outside = 0;
      const in_label = [I18n.get('Total')];
      const out_label = [I18n.get('Total')];

      const ts = story.length>0? story[story.length - 1].ts: null;

      useEffect(() => {
        axios.get('/attributes', { params: {'id': id }})
        .then((res) => {
          const data = JSON.parse(res.request.response);
          setAttrs(data);
        }).catch((error) => {  toast.error(error_format(error)) })

      }, [ts]);


      Object.keys(attrs).forEach(key => {
        if (key.startsWith('user'))
        { 
          idx = key.slice(4);
          //users[idx] = {'name': attrs[key], 'state': attrs['stat'+idx], 'last': attrs['lsts'+idx]}; //check null keys from attrs
          if (attrs['stat'+idx] === '1') { 
            inside += 1 ;
            in_label.push(attrs[key]);
          } else  {
            outside += 1;
            out_label.push(attrs[key]);
          }
        }
      });

    return PieBody([in_label, out_label], [inside, outside] );
}

const OfficeControl = ({ ...props }) => {
  return (     
        <div>
          <OfficeControlNowDialog card={props.card} />
        </div>   
    );
}

  export {Mock, MockControl, ODTemp, Office, OfficeControl, WatchDog, UnitXC, GateWay};