import React, { Component } from "react";
import {
  Nav,
  NavDropdown,
  MenuItem,
} from "react-bootstrap";
import history from 'components/Auth0/history';

import axios from 'axios';
import { connect } from 'react-redux';
import { update, clearApiToken } from 'server';
import { toast } from 'react-toastify';
import { I18n } from 'I18n.js';
import ReadMsgDialog from 'components/Dialogs/ReadMsgDialog';
//import { useAuth0 } from "components/Auth0/react-auth0-spa";
import { localtime, error_format, extractContent, prepareContentForBar, truncate } from 'utils';
import { URL_MANUAL, URL_SUPPORT, URL_DOCUMENTS } from 'server';

const LogoutItem = () => {
  //const { logout } = useAuth0();

  return ( <MenuItem eventKey={4.5} onClick={() => {clearApiToken(); window.hp('/')}} >
              <div className="text-danger">
                <i className="pe-7s-close-circle" /> {I18n.get('LogOut')}
              </div>
            </MenuItem>
  )
}

class HeaderLinks extends Component {
  state = { dialog: null, messages: [] };
  hide_msg = () =>
  {
    this.setState({dialog: null});
  }

  show_msg = (s) =>
  {
    //console.log(s);
    this.setState({dialog: <ReadMsgDialog msg={s} hide={this.hide_msg}/>});
    this.read_msg(s);
  }

  read_msg = (text) =>
  {
    axios.post('/info' , {type: 'mailread', 'msg': text})
    .then(() => {update();})
    .catch((error) => {  toast.error(error_format(error)) })
  }

  read_all = () =>
  {
    axios.post('/info' , {type: 'mailread'})
    .then(() => {update();})
    .catch((error) => {  toast.error(error_format(error)) })
  }

  get_mail = () =>
  {
    axios.get('/info', { params: { 'type': 'mail' } })
    .then((res) => {this.setState({messages: JSON.parse(res.request.response)});})

  }
  
  render() {
    const notifications =  this.state.messages && this.state.messages.slice(0).reverse().map(function(message, i)
      { 
        return (  <MenuItem key={i} eventKey={i} onClick={() => this.show_msg(extractContent(message.msg))}>
                  <p className={message.new?undefined:"text-muted"}>
                    {localtime(message.ts).format('LLL')} 
                    <br/>

                    {truncate(prepareContentForBar(message.msg), 29)}
                  </p>
                  </MenuItem>
                )
      }, this);
    
      if (window.ReactNativeWebView)
      {
        //console.log('post', JSON.stringify({new_mail: this.props.userinfo.mail, path:history.location.pathname }) );
        window.ReactNativeWebView.postMessage( JSON.stringify({new_mail: this.props.userinfo.mail, path:history.location.pathname }) );
      }


    return (
      <div>
        {this.state.dialog}
        <Nav pullRight>
          <NavDropdown onClick={this.get_mail}
            eventKey={3}
            title={
              <div>
                <i className="fa fa-bell-o" />
                {this.props.userinfo.mail && <span className="notification">{this.props.userinfo.mail}</span>}
                <p className="hidden-md hidden-lg">
                  {I18n.get('Notifications')}
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-2"
          >
           {notifications}
           <MenuItem divider />
           <MenuItem key={999} eventKey={999} onClick={this.read_all}>
            <p>
              {I18n.get('Mark all as read')}
            </p>
            </MenuItem>
          </NavDropdown>
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                {I18n.get('More')}
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
            <MenuItem eventKey={4.2} onClick={()=>window.open(URL_MANUAL, "_blank")}>
              <i className="pe-7s-help1" /> {I18n.get('Manual')}
            </MenuItem>
            <MenuItem eventKey={4.3} onClick={()=>window.open(URL_SUPPORT, "_blank")}>
              <i className="pe-7s-help1" /> {I18n.get('Support')}
            </MenuItem>
            <MenuItem eventKey={4.4} onClick={()=>window.open(URL_DOCUMENTS, "_blank")}>
              <i className="pe-7s-help1" /> {I18n.get('Documents')}
            </MenuItem>
            <MenuItem divider />
            <LogoutItem />
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}


const mapStateToProps = function(store) {
  return {
    userinfo: store.userinfo,
  };
}

export default connect(mapStateToProps)(HeaderLinks);
