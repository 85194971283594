import React, { useState } from 'react';
//import {Button, DropdownItem, Input, Label, FormGroup, Form } from 'reactstrap';
import axios from 'axios';
import {error_format} from 'utils';
import { I18n } from 'I18n.js';
import { update } from 'server';
import BaseDialog from './BaseDialog'
import {
  MenuItem,
  FormGroup,
  ControlLabel,
  FormControl,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import { toast } from 'react-toastify';

const RenameDialog = ({ ...props }) => {
  const {id, type, old, onSelect } = props;

  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');
  const [alias, setAlias] = useState('');

  const toggle = () => {
    setShow(!show);
    setMsg('');
  }

  const apply = () =>
  {
    if ((!alias) || ((type === 'card') && (old === alias)) ||
    ((type === 'thing') && (id === alias)))
    {
      setShow(false);
      return;
    }

    axios.post('/rename/'+ type, { 'from' : old, 'to': alias, 'id': id} )
    .then(() => {update(); setShow(false); setAlias(''); toast.info(I18n.get('Success')); })
    .catch((error) => { setMsg(error_format(error)); })
  }

  const title =I18n.get('Rename') + " " + old;
  const body = <form onSubmit={e => { e.preventDefault(); }}>
                <FormGroup>
                  <ControlLabel> {I18n.get('New name')}</ControlLabel>
                  <FormControl name="Alias" id="alias" defaultValue={old} placeholder={I18n.get('any unique name')} onChange={(e) => {setAlias(e.target.value)}} />
                </FormGroup>
              </form>

  return (
    <>
      {(type === 'card')?  <MenuItem onSelect={onSelect} onClick={toggle}> <i className="pe-7s-pen" /> {I18n.get('Rename')} </MenuItem>
                           : 
                          <OverlayTrigger placement="top" overlay={<Tooltip id="rename">{I18n.get('Rename')}</Tooltip>}>
                            <Button simple bsStyle="info" bsSize="lg" style={{padding: '16px 24px'}} onClick={toggle} >
                              <i className="pe-7s-pen" />
                            </Button>
                        </OverlayTrigger>
      }

      <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} apply_cb={apply} msg={msg} />
    </>
  );
}

export default RenameDialog;