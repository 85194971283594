import React, { useState } from 'react';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Form,
  InputGroup
} from "react-bootstrap";
 
import  { Doughnut } from "react-chartjs-2";
import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import moment from 'moment';

import Card from "components/Card.jsx";
import Button from "components/CustomButton.jsx";
import Sidebar from "views/Sidebar.jsx";
//import image from "assets/img/full-screen-image-3.jpg";
import UserNavbar from "views/UserNavbar.jsx";
import routes from "routes.js";
import InvoiceDialog from 'components/Dialogs/InvoiceDialog';
import axios from 'axios';
import { fromnow_local } from 'utils';
import { I18n } from 'I18n.js';
//import { get_plan } from 'content';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { error_format } from 'utils';
import { update, URL_PRICES } from "server.js"

const Lang = () => {return {'en': 'English', 'ru': 'Русский'}};
const rLang = {'English': 'en', 'Русский': 'ru'};

const Nav = () => {return {'static':  I18n.get('Static'), 'hide': I18n.get('Hide')}};
const rNav = {'Static': 'static', 'Hide': 'hide', 'Статичная': 'static', 'Всплывающая': 'hide'};

const plan_stack = () => { return [ I18n.get('Free'), I18n.get('Personal'), I18n.get('Basic'),  I18n.get('Professional')]};

const tz = moment.tz.names();

const doughnut_render = (value) =>
{
  return <Doughnut
  data = {{ labels: [1, 2],
    datasets: [
    {
      label: "%",
      pointRadius: 0,
      pointHoverRadius: 0,
      backgroundColor: ["#4acccd", "#f4f3ef"],
      borderWidth: 0,
      data: [value, 100-value]
    }
    ]
  }}

  options={ {
    elements: {
      center: {
        text: value+'%',
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 60 // Defualt is 20 (as a percentage)
      }
    },
    hover: {mode: null},
    cutoutPercentage: 90,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            display: false
          }
        }
      ]
    }
  }}
className="ct-chart ct-perfect-fourth"
height={300}
width={456}
/>
}


const UserProfile = ({ ...props }) => {

  const cards = props.userinfo.cards;
  const things = props.userinfo.things;
  const email = props.userinfo.user.email;
  const plan = props.userinfo.user.plan;
  const settings = props.userinfo.user.settings;
  const user_tz = props.userinfo.user.settings.tz;
  const current_plan = plan_stack()[plan.current];
  const [rub, setRub] = useState(300);

  //console.log(plan);
  const handleInputChange = e => {
    const {name, value} = e.target
    setInputs_set({...inputs_set, [name]: value})
 }

  const r_send = () => {
    axios.get('/bill',  { params: { 'coins' : rub }})
    .then((res) => { 
      const data = JSON.parse(res.request.response);
      const link_params = new URLSearchParams(data).toString();
      window.location.href = 'https://auth.robokassa.ru/Merchant/Index.aspx?' + link_params;
    }) 
    .catch((error) => {  toast.error(error_format(error)) })
  }


  const change_plan = () => {
    //console.log(plan_stack().indexOf(inputs_set.plan));
    axios.post('/info', { 'type' : 'plan', 'change': plan_stack().indexOf(inputs_set.plan)})
    .then(() => { toast.info(I18n.get('The plan will be updated at 03:00 MSK')); update(); }) 
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const write_settings = () => {
    axios.post('/info', { 'type' : 'settings', 'settings': {
      lang: rLang[inputs_set.lang], 
      nav: rNav[inputs_set.nav],
      altmail: inputs_set.altmail,
      tz: inputs_set.tz
      }})
      .then(() => {window.location.reload(); }) 
      .catch((error) => {  toast.error(error_format(error)) })
  }
  // moment.tz.guess()
  const [inputs_set, setInputs_set] = useState({ lang: Lang()[settings.lang], nav: Nav()[settings.nav], altmail: settings.altmail, plan: current_plan, tz: user_tz? user_tz: 'UTC'});

  const write_tapi = () =>
  {    
    axios.post('/info', { 'type' : 'tapi', 'code': inputs_tapi})
    .then(() => { toast.info(I18n.get('Success')); }) 
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const [inputs_tapi, setInputs_tapi] = useState('');
  
  const write_mailcode = () =>
  {
    axios.post('/info', { 'type' : 'mailcode', 'code': inputs_mailcode})
    .then(() => { toast.info(I18n.get('Success')); }) 
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const [inputs_mailcode, setInputs_mailcode] = useState('');

  const active_things = things.filter(thing => ((thing.cond.owner === null) || (thing.cond.owner === email) || thing.cond.ack)).length;

  return (
      <div className="wrapper">
        <Sidebar routes={routes} color={"gray"} hasImage={false} mini={true} />
        <div className={"main-panel"}>
          <UserNavbar handleMiniClick={()=>{document.body.classList.toggle("sidebar-mini")}} navbar={false} /> 

          <div className="main-content">
            <Grid fluid>
              <Row>
                <Col md={6}>
                  <Card
                    title={I18n.get('Profile')}
                    content={<div>
                    <p>{I18n.get('Account') + ': ' + email}</p>
                    <p>{I18n.get('Current plan') + ': ' + current_plan + " "} (<a href={URL_PRICES} rel="noopener noreferrer" target="_blank">{I18n.get('About plans')}</a>)</p>
                    <p>{I18n.get('Time left') + ': '} {(plan.eos>-1)? plan.eos + ' ' + I18n.get('days') : I18n.get('blocked')}</p>
                    {(plan.current !== plan.next) && <p>{I18n.get('Tomorrow plan') + ': ' + plan_stack()[plan.next]}</p>}
                    <p> {I18n.get('Change the plan')} </p>
                    <Row>
                    <Col xs={6}>
                      <FormControl componentClass="select" name="plan" onChange={handleInputChange} defaultValue={current_plan}>
                        {plan_stack().map(function(plan, index) {
                            return (<option key={index}>{I18n.get(plan)}</option>) 
                            })}
                      </FormControl>
                      </Col>

                      <Col xs={3}>
                      <Button fill bsStyle="primary" onClick={change_plan}>
                        {I18n.get('Change')}
                      </Button>
                      </Col>
                    </Row>
                    <br/>
                    </div>}
                  />
                </Col>
               
                <Col md={6}>
                  <Card
                      title={I18n.get('Balance')}
                      content={<div>
                       <p>{I18n.get('Balance') + ': '+ plan.balance}</p>
                       <Row>
                        <Col xs={6}>
                          <FormControl  value={rub} onChange={(e) => {setRub(e.target.value)}} autoComplete="off"/> 
                        </Col>
                       <Col xs={6}>
                      <Button fill bsStyle="primary" onClick={r_send}> {I18n.get('Recharge')} </Button>
                      </Col>
                      </Row>
                      <br/>
                      <Row>
                      <Col xs={6}>
                      <p>{I18n.get('For companies')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<InvoiceDialog price={rub}/></p> 
                      </Col>
                      </Row>
                      <br/><br/><br/>
                      </div>}
                    />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Card
                        title={I18n.get('Settings')}
                        content={
                          <form>
                            <FormGroup>
                              <ControlLabel>{I18n.get('Language')}</ControlLabel>
                                <FormControl componentClass="select" name="lang" id="lang" onChange={handleInputChange} defaultValue={inputs_set.lang}>
                                  {Object.keys(Lang()).map(function(key, index) { return (<option key={index}>{Lang()[key]}</option>) })}
                                </FormControl>
                            </FormGroup>

                            <FormGroup>
                              <ControlLabel>{I18n.get('Sidebar')}</ControlLabel>
                              <FormControl componentClass="select" name="nav" id="nav" onChange={handleInputChange} defaultValue={inputs_set.nav}>
                                {Object.keys(Nav()).map(function(key, index) { return (<option key={index}>{Nav()[key]}</option>) })}
                              </FormControl>
                            </FormGroup>

                            <FormGroup>
                              <ControlLabel>{I18n.get('TimeZone')}</ControlLabel>
                              <FormControl componentClass="select" name="tz" id="tz" onChange={handleInputChange} defaultValue={inputs_set.tz}>
                                {tz.map(function(key, index) { return (<option key={index}>{key}</option>) })}
                              </FormControl>
                            </FormGroup>

                            <Button bsStyle="primary" fill onClick={write_settings}>
                              {I18n.get('Apply')}
                            </Button>
                          </form>
                        }
                      />
                </Col>

                <Col md={6}>
                <Card
                      title={I18n.get('Notifications')}
                      content={
                        <form>
                          <FormGroup>
                            <ControlLabel>{I18n.get('Telegram code from @unitx_notifier_bot')}</ControlLabel>
                            <FormControl placeholder={I18n.get(settings.tstatus?'confirmed':'unconfirmed')} value={inputs_tapi.tapi} onChange={(e) => {setInputs_tapi(e.target.value)}} autoComplete="off"/>
                         <br/>
                            <Button bsStyle="primary" fill  onClick={write_tapi}>
                          {I18n.get('Send')} 
                          </Button>
                          </FormGroup>
                     

                          <FormGroup>
                            <ControlLabel>{I18n.get('Mail code')}</ControlLabel>
                            <FormControl placeholder={I18n.get(settings.estatus==='confirmed'?'confirmed':'unconfirmed')} value={inputs_mailcode.mailcode} name="mailcode"  onChange={(e) => {setInputs_mailcode(e.target.value)}} autoComplete="off"/>
                        <br/>
                        <Button bsStyle="primary" fill  onClick={write_mailcode}>
                          {I18n.get('Send')} 
                          </Button>
                          </FormGroup>
                     
                        </form>
                      }
                    />
                </Col>

              </Row>

              <Row>
              <Col md={3}>
                  <Card
                    title={I18n.get('Cards')}
                    category={I18n.get('Used')}
                    content={doughnut_render(Math.round(cards.length*100/(cards.length + plan.leftover.cards)))}
                    stats={
                      <div>
                        <i className="fa fa-history" />
                        {cards.length + " " + I18n.get('from ') + (cards.length + plan.leftover.cards)}
                      </div>
                    }
                  />
                </Col>

                <Col md={3}>
                  <Card
                    title={I18n.get('Things')}
                    category={I18n.get('Used')}
                    content={doughnut_render(Math.round(active_things*100/(active_things + plan.leftover.things)))}
                    stats={
                      <div>
                        <i className="fa fa-history" />
                        {active_things + " " + I18n.get('from ') +  (active_things + plan.leftover.things)}
                      </div>
                    }
                  />
                </Col>

              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
}



const mapStateToProps = function(store) {
  return {
    userinfo: store.userinfo,
  };
}

export default connect(mapStateToProps)(UserProfile);