import axios from 'axios';

import { I18n } from 'I18n.js';
import moment from 'moment';
import 'moment/min/locales';
import { boundUserInfo, boundApiCardsThings } from 'redux-utils';
import { toast } from 'react-toastify';
import {error_format} from 'utils';

export const WEB_URL = 'https://cloud.unitx.pro';
export const MQTT_SERVER = 'api.unitx.pro';
const API_BASE_URL = 'https://api.unitx.pro/api';

export const URL_MANUAL = 'https://unitx.pro/cloud';
export const URL_SUPPORT = 'https://help.unitx.pro';
export const URL_DOCUMENTS = 'https://unitx.pro/cloud-license';
export const URL_PRICES = 'https://unitx.pro/cloud-pricing';

export const locale = require('browser-locale')()


export const serverState = {
    connected: false,   
 };

axios.defaults.baseURL = API_BASE_URL; 

export const setApiToken = (USERNAME, PASSWORD) => {
    const token = `${USERNAME}:${PASSWORD}`;
    const encodedToken = Buffer.from(token).toString('base64');
    const headers = { 'Authorization': 'Basic '+ encodedToken };
    axios.defaults.headers.common['Authorization'] = 'Basic '+ encodedToken;
    //axios.defaults.baseURL = API_BASE_URL; 

    //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //axios.defaults.baseURL = API_BASE_URL; 
}

export const clearApiToken = () => {
 
  axios.defaults.headers.common['Authorization'] = null;
  //xios.defaults.baseURL = null; 

  //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //axios.defaults.baseURL = API_BASE_URL; 
}


export const getUserInfo = async () => {
  try {
      let res = await axios.get('/info', { params: { 'type': 'all' } });
      return res.data;
    }
    catch (err) {
      clearApiToken();
      //window.hp('/');
      return null;
      //alert('Server internal error. Try to login later.'); 
      
      // this.props.auth.logout();
   }
  return null;
}

export function setSettings(lang, nav) {
  if (lang === '') //first time
  {
    const lang = locale.startsWith('ru')?'ru':'en';
    moment.locale(lang);
    I18n.setlang(lang);
    axios.post('/info', { 'type' : 'settings', 'settings': {lang: lang, nav: 'hide'}});
  } 
  else
  {
    moment.locale(lang);
    I18n.setlang(lang);
  }

  if (nav === 'hide')
  {
    document.body.classList.add("sidebar-mini");
  }
}

export function update(all)
{
  //console.log('Update');
  axios.get('/info', { params: { 'type': all?'all':'short' } })
  .then((res) =>
  {
    const userInfo = JSON.parse(res.request.response);
    setSettings(userInfo.user.settings.lang, userInfo.user.settings.nav);
    boundUserInfo(userInfo);              
    boundApiCardsThings({cards: userInfo.cards, things: userInfo.things});
  })
  .catch((error) => {  toast.error(error_format(error)) })
}