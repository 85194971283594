import React, { useState } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { I18n } from 'I18n.js';
import axios from 'axios';
import { useAuth0 } from "components/Auth0/react-auth0-spa";
import { error_format } from 'utils';
import { toast } from 'react-toastify';

function LockPlan() {
  const [fallback, setFallback] = useState(false);
  const { logout } = useAuth0();

  function gotoshop(){
    window.open("https://open-dev.ru/shop#cloud", "_self")
  }
  
  function downgrade(){
    axios.post('/fallback')
    .then(() => {setFallback(true)})
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const initial = <ReactBSAlert
                      warning
                      style={{ display: "block", marginTop: "-300px" }}
                      title={I18n.get('Your plan was expired')}
                      onConfirm={() => gotoshop()}
                      onCancel={() => downgrade()}
                      confirmBtnBsStyle="info"
                      cancelBtnBsStyle="danger"
                      confirmBtnText={I18n.get('Go to the shop!')}
                      cancelBtnText={I18n.get('Downgrade')}
                      showCancel
                    >
                      {I18n.get('Your plan was expired: 2 days later it will be downgraded to the Free plan. Over cards & things will be removed.')}<br/>
                      {I18n.get('You can pay or change plan to Free immediately')}<br/>
                    </ReactBSAlert>

  const changed = <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-300px" }}
                    title={I18n.get('Your plan was changed!')}
                    onConfirm={() => logout()}
                    onCancel={() => logout()}
                    confirmBtnBsStyle="info"
                  >
                    {I18n.get('Your plan is Free')}
                  </ReactBSAlert>
  


  return (
    <div className="register-page">
      {fallback? changed: initial}
    </div>
  );
}

export default LockPlan;
