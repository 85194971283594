import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { I18n } from 'I18n.js';
import history from 'components/Auth0/history';

const locale = require('browser-locale')()

export function PaySuccess() {
    const lang = locale.startsWith('ru')?'ru':'en';
    I18n.setlang(lang);
    return (
      <div className="register-page">
        <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title={I18n.get('Payement is done')}
              onConfirm={() => history.push('/profile')}
            >
            {I18n.get('Your payement is done. Thanks! :)')}<br/>
            </ReactBSAlert>
      </div>
    );
}

export function PayFail() {
    const lang = locale.startsWith('ru')?'ru':'en';
    I18n.setlang(lang);
    return (
      <div className="register-page">
        <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title={I18n.get('Fail')}
              onConfirm={() => history.push('/')}
              confirmBtnBsStyle="warning"
            >
              {I18n.get('Your payement is failed :(')}<br/>
            </ReactBSAlert>
      </div>
    );
}
