import { getRandomInt } from 'utils' 

import React, { useState } from 'react';

import {error_format} from 'utils';
import axios from 'axios';
import { I18n } from 'I18n.js';
import { update } from 'server';
import {
   Modal,
   FormGroup,
   ControlLabel,
   FormControl,
 } from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'
import { toast } from 'react-toastify';
import { pdfTemplate } from 'pdf_template';

var fileDownload = require('js-file-download');
//var pdf = require('html-pdf');
var dateFormat = require('dateformat')

const InvoiceDialog = ({ ...props }) =>  {
  const { price } = props;

  const [show, setShow] = useState(false);
  const [inn, setInn] = useState('');
  const [msg, setMsg] = useState('');
  const [gen, setGen] = useState(true);

  const title = I18n.get('Invoice');
  
  const toggle = () => {
    setShow(!show);
    setMsg('');
    setGen(!show);
  }

  const getinfo = () => {
    if (inn !== ''){
        const billNumber = dateFormat(new Date(), 'ddmmyy') + '-' + String(getRandomInt(65535));
        axios.get('/jpinfo', { params: {'party': inn, 'num': billNumber, 'sum': String(price)} } )
        .then((res) => {
        const data = JSON.parse(res.request.response);
        const html = pdfTemplate({name: data.name, inn: inn, kpp: data.kpp, price: price, billNumber: billNumber});
        fileDownload(html, 'UnitX_invoice.html');
        setMsg(I18n.get('The invoice is downloaded'));
        setGen(false);
        })
        .catch((error) => {  toast.error(error_format(error)) })
    }
   }

   const body =  <form onSubmit={e => { e.preventDefault(); }}>
    <FormGroup>
        <ControlLabel>{I18n.get('INN')}</ControlLabel>
        <FormControl type="text" name="inn" id="inn" placeholder={I18n.get('INN')} onChange={(e) => {setInn(e.target.value)}}/>
    </FormGroup>
    </form>


    const footer =  <Modal.Footer>
                {gen && <Button simple onClick={getinfo}> {I18n.get('Generate')} </Button>}
                <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
            </Modal.Footer>

  return (
        <>
          <Button fill bsStyle="primary" onClick={toggle}> {I18n.get('Invoice')} </Button>
          <BaseDialog show={show} onhide_cb={toggle} title={title} body={body} footer={footer} msg={msg}/>
        </>
          );
               

}
export default InvoiceDialog;