import React, { useState } from 'react';
//import { Button, DropdownItem, Form, FormGroup, ControlLabel, FormControl } from 'reactstrap';
import {error_format} from 'utils';
import { I18n } from 'I18n.js';
import axios from 'axios';
import { update } from 'server';
import {
  MenuItem,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'

const dest = ['none', 'mbox', 'email', 'telegram'];

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

const TaskDialog = ({ ...props }) => {
  const { alias, task, vars, onSelect } = props;
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');

  const cond = {'>':'>', '<': '<', '>=': '>=', '<=': '<=', '==': '==', '!=' :'!=', '&': '&', '!&': '!&'}
  //cond[I18n.get('any')] =  'fwd';
  cond[I18n.get('offline')] = 'die';
  cond[I18n.get('online')] = 'up';
  //console.log(task);
  const [inputs, setInputs] = useState({var: task.var? task.var: vars[0], cond: task.cond? task.cond: '>', dest: task.dest? task.dest: dest[0],
    value: task.value, delta: task.delta, freq: task.freq })

  const handleInputChange = e => {
  const {name, value} = e.target
  setInputs({...inputs, [name]: value})
  }

  const toggle = () => {
    setShow(!show);
    setMsg('');
  }
  
  const clear = () =>
  {
    const params = {'task': {}, name: alias};
    axios.post('/card/task', params)
    .then(() => {update(); toggle();})
    .catch((error) => { setMsg(error_format(error)); })
  }

  const apply = () =>
  {
    const mtask = {
      var: inputs.var,
      cond: cond[inputs.cond],
      value: inputs.value? inputs.value: task.value,
      delta: inputs.delta? inputs.delta: task.delta,
      freq: inputs.freq? inputs.freq*60: task.freq,
      dest: inputs.dest
    }

    if ((mtask.value === undefined) || (mtask.delta === undefined)  || (mtask.freq === undefined))
    {
      setMsg(I18n.get('Please, fill every field!'));
    }else
    {
      mtask.value = parseFloat(mtask.value);
      mtask.delta = parseFloat(mtask.delta);
      mtask.freq = parseFloat(mtask.freq);

      const params = {'task': mtask, name: alias};
      axios.post('/card/task', params)
      .then(() => {setMsg(I18n.get('Task updated!')); update();})
      .catch((error) => { setMsg(error_format(error)); })
      }
  }
  //console.log(Object.keys(cond));

  const title = I18n.get('Task');
  //console.log(inputs.cond, cond[inputs.cond],  task.cond);
  const body = <>  <div>
                          <form onSubmit={e => { e.preventDefault(); }}>
                            <FormGroup>
                              <ControlLabel>{I18n.get('Variable')}</ControlLabel>
                              <FormControl componentClass="select" name="var" id="var" onChange={handleInputChange} defaultValue={task.var}>
                              { vars.map((_var, i) => ( <option key={i}>{_var}</option> ))}
                              </FormControl>
                            </FormGroup>

                            <FormGroup>
                              <ControlLabel>{I18n.get('Сondition')}</ControlLabel>
                              <FormControl componentClass="select" name="cond" id="cond" onChange={handleInputChange} defaultValue={getKeyByValue(cond, task.cond)}>
                              { Object.keys(cond).map((_var, i) => ( <option key={i}>{_var}</option> ))}
                              </FormControl>
                            </FormGroup>
                            { ((cond[inputs.cond]!== 'die') && (cond[inputs.cond] !== 'up')) &&
                            <div>
                            <FormGroup>
                              <ControlLabel>{I18n.get('Value')}</ControlLabel>
                              <FormControl componentClass="input" type="number" name="value" id="value" onChange={handleInputChange} defaultValue={task.value}/>
                            </FormGroup>

                            <FormGroup>
                              <ControlLabel>{I18n.get('Comparison threshold')}</ControlLabel>
                              <FormControl componentClass="input" type="number" name="delta" id="delta" onChange={handleInputChange} defaultValue={task.delta}/>
                            </FormGroup>
                            </div>
                            }
                            <FormGroup>
                              <ControlLabel>{I18n.get('Rate limit, minutes')}</ControlLabel>
                              <FormControl componentClass="input" type="number" name="freq" id="freq" onChange={handleInputChange} defaultValue={task.freq? task.freq/60: task.freq}/>
                            </FormGroup>

                            <FormGroup>
                              <ControlLabel>{I18n.get('Destination')}</ControlLabel>
                              <FormControl componentClass="select" name="dest" id="dest" onChange={handleInputChange} defaultValue={task.dest}>
                              { dest.map((_var, i) => ( <option key={i}>{_var}</option> ))}
                              </FormControl>
                            </FormGroup>
                            {Object.keys(task.length>0) &&
                            <div style={{textAlign: 'center'}}>
                                <Button wd bsStyle="danger" onClick={clear}>
                                  <span className="btn-label">
                                    <i className="fa fa-times" />
                                  </span>
                                    {I18n.get('Delete')}
                                </Button>
                            </div>
                        }
                        </form>

                     
                      </div>
                    
                  </>
  return (
    <>
      <MenuItem onSelect={onSelect} onClick={() => setShow(true)}> <i className="pe-7s-shuffle" /> {I18n.get('Task')} </MenuItem>
      <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} apply_cb={apply} msg={msg} />
    </>
  );

}

export default TaskDialog;