import { createStore } from 'redux';

var apiReducer = function(state, action) {
    if (state === undefined) {
        state = {};
    }
    if (action.type === 'API_USERINFO') {
        /*  
        console.log('old');
        console.log(state.userinfo);
        console.log('new');
        console.log(action.value);
        */
        if (state.userinfo) //ToDo: is it safe ?
        {
                action.value.cards.forEach(function(card){
                    state.userinfo.cards.forEach(function(card_wstory){
                    if (card.id === card_wstory.id)
                    {    
                        card.story = card_wstory.story;
                    }
                });
            });
        }
        return Object.assign({}, state, { userinfo: action.value });
    }
    if (action.type === 'API_CONNECTED') {
        const newState = Object.assign({}, state, { connected: action.value });
        return newState;
    }
    if (action.type === 'API_MQTT') {
        const newState = Object.assign({}, state, { mqtt: action.value });
        return newState;
    }
    if (action.type === 'API_CARDS') {
        const newState = Object.assign({}, state, { cards: action.value });
        return newState;
    }
    if (action.type === 'API_THINGS') {
        const newState = Object.assign({}, state, { things: action.value });
        return newState;
    }

    if (action.type === 'API_CARDS_THINGS') {
        const newState = Object.assign({}, state, { cards_things: action.value });
        return newState;
    }
    
    return state;
}

/*
const reducers = combineReducers({
    user: userReducer,
    api: apiReducer
});
*/
  

export function putMqttHead(value) {
    return {
        type: 'API_MQTT',
        value: value
    }
}

export function putUserInfo(value) {
    return {
        type: 'API_USERINFO',
        value: value
    }
}

export function putApiConnected(value) {
    return {
        type: 'API_CONNECTED',
        value: value
    }
}

export function putApiCards(value) {
    return {
        type: 'API_CARDS',
        value: value
    }
}

export function putApiThings(value) {
    return {
        type: 'API_THINGS',
        value: value
    }
}

export function putApiCardsThings(value) {
    return {
        type: 'API_CARDS_THINGS',
        value: value
    }
}

export function putApiUserSettings(value) {
    return {
        type: 'API_USERSETTINGS',
        value: value
    }
}

export var store = createStore(apiReducer);

export const boundUserInfo = (value) => store.dispatch(putUserInfo(value));  
export const boundApiCards = (value) => store.dispatch(putApiCards(value));
export const boundApiThings = (value) => store.dispatch(putApiThings(value));
export const boundApiCardsThings = (value) => store.dispatch(putApiCardsThings(value));
export const boundApiConnected = (value) => store.dispatch(putApiConnected(value));
export const boundUserSettings = (value) => store.dispatch(putApiUserSettings(value));
export const boundMqttHead = (value) => store.dispatch(putMqttHead(value));