import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { I18n } from 'I18n.js';
import { WEB_URL } from 'server.js';
const locale = require('browser-locale')()


function Verify() {
    const lang = locale.startsWith('ru')?'ru':'en';
    I18n.setlang(lang);
    return (
      <div className="register-page">
        <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={I18n.get('Verification pending')}
              onConfirm={() => window.location.href=WEB_URL}
              confirmBtnBsStyle="warning"
            >
              {I18n.get('Please verify your email before logging in.')}<br/>
              {I18n.get('Verification letter was sent for you')}
            </ReactBSAlert>
      </div>
    );

}

export default Verify;
