import React, { useState } from 'react';

import { error_format } from 'utils';
import axios from 'axios';
import { I18n } from 'I18n.js';
import { update } from 'server';
import { toast } from 'react-toastify';

import {
  ControlLabel,
  FormControl,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'

const ToDashDialog = ({ ...props }) => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');
  const [alias, setAlias] = useState(props.alias);

  const toggle = () => {
    setShow(!show);
    setMsg('');
  }

  const apply = () =>
  {
    axios.post('/new/card', {'id': props.id, 'alias': alias})
    .then(() => { setShow(false); toast.info(I18n.get('The card is added') + ': ' + alias); update(true); })
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const title = I18n.get('To the dashboard');
  const body =  <form onSubmit={e => { e.preventDefault(); }}>
                  <br/>
                  <ControlLabel>{I18n.get('Enter any apropriate name')}</ControlLabel>
                  <br/>
                  <br/>
                  <FormControl name="Alias" id="alias" placeholder={I18n.get('any name')} value={alias} onChange={(e) => {setAlias(e.target.value)}}/>
                  <br/>
                  <br/>
                </form>;

  return (
    <>
      {props.dialog? <Button bsStyle="success" fill onClick={toggle}  > {I18n.get('To the dashboard')}</Button>:
      <OverlayTrigger placement="top" overlay={<Tooltip id="todash">{I18n.get('To the dashboard')}</Tooltip>}>
        <Button simple bsStyle="success" bsSize="lg" style={{padding: '16px 24px'}} onClick={toggle} >
          <i className="pe-7s-keypad" />
        </Button>
      </OverlayTrigger>
      }
    <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} apply_cb={apply} msg={msg} />

    </>
  );
}

export default ToDashDialog;