import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { I18n } from 'I18n.js';
import { locale, WEB_URL } from 'server.js';

export default function VerifyOK() {
    const lang = locale.startsWith('ru')?'ru':'en';
    I18n.setlang(lang);
    return (
      <div className="register-page">
        <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title={I18n.get('Email is confirmed')}
              onConfirm={() => window.location.href = WEB_URL+'/dashboard'}
            >
            {I18n.get('Your Email is confirmed. Thanks!')}<br/>
            </ReactBSAlert>
      </div>
    );
}