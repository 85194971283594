import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import Things from "views/Things.jsx";

import { I18n } from 'I18n.js';

const routes = () => {
  return [
  {
    path: "/dashboard",
    name: I18n.get('Dashboard'),
    icon: "pe-7s-keypad",
    component: Dashboard,
    layout: ""
  },
  {
    path: "/things",
    name: I18n.get('Things'),
    icon: "pe-7s-share",
    component: Things,
    layout: ""
  },
/*
  {
    path: "/regdoc",
    name: I18n.get('Connect'),
    icon: "nc-icon nc-box-2",
    component: RegDoc,
    layout: ""
  },
*/
  {
    path: "/profile",
    name: I18n.get('Profile'),
    icon: "pe-7s-users",
    component: UserProfile,
    layout: ""
  },
]
}
export default routes;
