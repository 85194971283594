import React from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-zoom';
import { localtime  } from 'utils';

const Chart = require("chart.js");

Chart.pluginService.register({
    beforeDraw: function(chart) {
      if (chart.config.options.elements.center) {
        //Get ctx from string
        var ctx = chart.chart.ctx;
  
        //Get options from the center object in options
        var centerConfig = chart.config.options.elements.center;
        var fontStyle = centerConfig.fontStyle || "Arial";
        var txt = centerConfig.text;
        var color = centerConfig.color || "#000";
        var sidePadding = centerConfig.sidePadding || 20;
        var sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2);
        //Start with a base font of 30px
        ctx.font = "30px " + fontStyle;
  
        //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        var stringWidth = ctx.measureText(txt).width;
        var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
  
        // Find out how much the font can grow in width.
        var widthRatio = elementWidth / stringWidth;
        var newFontSize = Math.floor(30 * widthRatio);
        var elementHeight = chart.innerRadius * 2;
  
        // Pick a new font size so it will not be larger than the height of label.
        var fontSizeToUse = Math.min(newFontSize, elementHeight);
  
        //Set font settings to draw it correctly.
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = fontSizeToUse + "px " + fontStyle;
        ctx.fillStyle = color;
  
        //Draw text in center
        ctx.fillText(txt, centerX, centerY);
      }
    }
  });
  
  const LineGraph = ({ ...props }) => {
    const { labels, values, task_val, task_id, yAxes, tooltips_callbacks, resizable } = props;

    let annotation = undefined;
      if (task_val !== undefined)
      {
        annotation = {
          annotations: [{
            drawTime: 'afterDatasetsDraw',
            borderColor: 'red',
            borderDash: [15, 30],
            type: 'line',
            mode: 'horizontal',
            scaleID: task_id? task_id: 'unique_id',
            value: task_val,
            borderWidth: 1,
            label: {
              enabled: false,
            }
          }]
        }
      }
  

      const datasets = values.map(function(val, i) {

        return  {
                  label: val.key,
                  yAxisID: (values.length>1)?val.key:undefined,
                  fill: true,
                  lineTension: 0.3,
                  borderColor: val.color,
                  backgroundColor: val.bgcolor,
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: val.color,
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: val.color,
                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: val.data,
                  steppedLine: val.stepped,
                }
      });
  
    const options = {
      responsive: true,
      tooltips: {
        mode: 'label',
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
            type: 'time',
            time: {
              tooltipFormat:'HH:mm DD/MM/YYYY',
              displayFormats: {
                minute: 'HH:mm(D)',
                hour: 'HH:mm(D)',
              }
            }
        }],
        

        yAxes: [{
          ticks: {
              suggestedMin: 0,
          }
          },
        ]        
      },
      
      pan:{
        enabled: resizable,
        mode:'xy',
        //onPanComplete: function({chart}) { console.log(`I was panned!!!`); }
     },
    zoom:{
        enabled: resizable,
        drag: false,
        mode:'xy',
        //onZoomComplete: function({chart}) { console.log(`I was zoomed!!!`); }
    },


      annotation: annotation,
      };
      if (yAxes) { options.scales.yAxes = yAxes; }
      if (tooltips_callbacks) { options.tooltips.callbacks = tooltips_callbacks; }
      //console.log(datasets);
      //console.log(options);
      return(<Line data={{labels: labels, datasets: datasets }} options={options}/>);
  }
  
const BarGraph = ({ ...props }) => {
      const { labels, values, task_val, yAxes, tooltips_callbacks, task_id } = props;
     
      let annotation = undefined;
      if (task_val !== undefined)
      {
        annotation = {
          annotations: [{
            drawTime: 'afterDatasetsDraw',
            borderColor: 'red',
            borderDash: [15, 30],
            type: 'line',
            mode: 'horizontal',
            scaleID: task_id,
            value: task_val,
            borderWidth: 1,
            label: {
              enabled: false,
            }
          }]
        }
      }
  
      const datasets = values.map(function(val, i) {
          return  {
                    label: val.key,
                    borderColor: val.color,
                    backgroundColor: val.bgcolor,
                    pointBorderColor: val.color,
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: val.color,
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: val.data,
                    yAxisID: val.yAxisID,
                    type: val.type,
                  }
      });
  
     const options = {
      responsive: true,
      tooltips: {
        mode: 'label',
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          barThickness : 5,
            type: 'time',
            time: {
              tooltipFormat:'HH:mm DD/MM/YYYY',
              displayFormats: {
                minute: 'HH:mm(D)',
                hour: 'HH:mm(D)',
              }
            },
        }],
        
      },
  
      annotation: annotation,
  
      };
      if (yAxes) { options.scales.yAxes = yAxes; }
      if (tooltips_callbacks) { options.tooltips.callbacks =  tooltips_callbacks}
      return(<Bar data={{labels: labels, datasets: datasets }} options={options}/>);
  }

const PieGraph = ({ ...props }) => {
    const { labels, values } = props;
    const datasets = [{
      data: values,
      backgroundColor: [
      '#36A2EB',
      '#FF6384'
      ],
      hoverBackgroundColor: [
      '#36A2EB',
      '#FF6384'
      ]
    }]


   const options = {
    responsive: true,
    tooltips: {
      mode: 'label',
    },
    legend: {
      display: false
    },

    };
    
    return(<Pie data={{labels: labels, datasets: datasets }} options={options}/>);
}

const make_date_label = (ts) => {
    return localtime(ts);
}

export const labels_prepare = (story) => {
    return story.map(function(step)
    {
      return make_date_label(step.ts);
    })
}
  
export const LineBody = ({ ...props }) =>
{
  const { story, attrs, color, labels_prepare, values_prepare } = props;
  return (<LineGraph labels={labels_prepare(story)} values={values_prepare(story, attrs, color)} {...props} />);
}

export const BarBody = ({ ...props }) =>
{
  const { story, attrs, color, labels_prepare, values_prepare } = props;
  return (<BarGraph labels={labels_prepare(story)} values={values_prepare(story, attrs, color)} {...props} />);
}

export const PieBody = (labels, values ) =>
{
  return (<PieGraph labels={labels} values={values}/>);
}


export const TextBody = ({ ...props }) =>
{
  //const { story, attrs, color, yAxes, tooltips_callbacks, task_val, task_id, labels_prepare, values_prepare } = props;
  return (<div></div>);
}
