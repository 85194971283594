import React, { useState } from 'react';
//import {Button, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {error_format} from 'utils';
import { I18n } from 'I18n.js';
import { update } from 'server';
import {
  Modal,
  MenuItem,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'

const DeleteDialog = ({ ...props }) => {
  const {deletething, thing_id, alias, onSelect} = props;

  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');
  
  const title = I18n.get('Delete');
  const body = props.deletething? I18n.get('Are you sure?'): I18n.get('Are you sure? You can add it again later.')

  const toggle = () => {
    setShow(!show);
    setMsg('');
  }

  const apply = () =>
  {
    const api = deletething? '/remove/thing': '/remove/card';
    const params = deletething? {'id': thing_id}:  {'id': alias};

    axios.post(api, params)
    .then((res) => { update(); })
    .catch((error) => { setMsg(error_format(error)); })
    toggle();
  }
  
  return (
    <>
      { deletething?  <OverlayTrigger placement="top" overlay={<Tooltip id="delete">{I18n.get('Delete')}</Tooltip>}>
                        <Button simple bsStyle="danger" style={{padding: '16px 24px'}} bsSize="lg" onClick={toggle}>
                          <i className="fa fa-times" />
                        </Button>
                      </OverlayTrigger>
      :
                      <MenuItem onClick={toggle} onSelect={onSelect}>
                            <div className="text-danger">
                              <i className="pe-7s-close-circle" />  {I18n.get('Delete')} 
                            </div>
                      </MenuItem>
      }

      <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} apply_cb={apply} msg={msg} />
    </>
  );

}

export default DeleteDialog;