import React from "react";
import {Mock, MockControl, ODTemp, WatchDog, UnitXC, Office, OfficeControl, GateWay} from 'components/Devices/devices.jsx';

import Card from "components/Card.jsx";
import { Col, MenuItem, DropdownButton } from "react-bootstrap";

import HistoryDialog from 'components/Dialogs/HistoryDialog';
import TaskDialog from 'components/Dialogs/TaskDialog';
import DeleteDialog from 'components/Dialogs/DeleteDialog';
import RenameDialog from 'components/Dialogs/RenameDialog';
import OfficeControlReport from 'components/Dialogs/OfficeControlReport';

import { I18n } from 'I18n.js';
import { fromnow_local, colors, get_username_from_attrs } from 'utils';
import { update } from 'server';
import WatchDogControl from 'components/Devices/controls';

function bodyPreparer(card, mqtt)
{
  const type = card.id.split('_')[0]; 
  var body = <div></div>;
  var val = "n/a";
  var color = {};
  var control = <div className="pull-right">{" "}</div>;

  switch (type)
  {
    case 'odtemp1u':
    case 'odtemp2t':
    case 'unitxt':
      color.t = colors[1];
      body = <ODTemp story={card.story} id={card.id} color = {color} yAxes={[{id: 't', ticks: {suggestedMin: 0}}]} task_val={card.task.value} task_id={card.task.var}/>
      if (card.current && card.current.state)
      {
        val = (<div>
            <i className="fa fa-circle" style={{ color: color.t}} />
            {" t: " + card.current.state.t}
        </div>)
      }
      break;
    case 'odtemp1w':
    case 'odtemp2h':
    case 'unitxtempw':
    case 'unitxd':
    case 'unitxh':
        color.t = colors[1];
        color.h = colors[2];
        body = <ODTemp story={card.story} id={card.id} color = {color} 
                        yAxes={[{id: 't', position: 'left',
                        ticks: {suggestedMin: 0}}, 
                        {id: 'h', position: 'right',
                        ticks: {suggestedMin: 0}}]} 
                        task_val={card.task.value} task_id={card.task.var}
                        />
        if (card.current && card.current.state)
        {
          val = (<div>
            <i className="fa fa-circle" style={{ color: color.t}} />
            {" t: " + card.current.state.t + "\t"}
            <i className="fa fa-circle" style={{ color: color.h}} />
            {" h: " + card.current.state.h}
          </div>)
        }
        break;
    case 'unitxc':
      color.c = colors[4];
      body = <UnitXC story={card.story} id={card.id} color = {color} yAxes={[{id: 'ppm', ticks: {suggestedMin: 400}}]} task_val={card.task.value} task_id={card.task.var}/>
      if (card.current && card.current.state)
      {
        val = (<div>
            <i className="fa fa-circle" style={{ color: color.c}} />
            {" ppm: " + card.current.state.c}
        </div>)
      }
      break;
    case 'gateway':
      color.c = colors[21];
      body = <GateWay story={card.story} id={card.id} color = {color} yAxes={[{id: 'count', ticks: {suggestedMin: 0}}]} task_val={card.task.value} task_id={card.task.var}/>
      if (card.current && card.current.state)
      {
        val = (<div>
            <i className="fa fa-circle" style={{ color: color.c}} />
            {" c: " + card.current.state.count}
        </div>)
      }
      break;
    case 'mockthing':
        body = <Mock story={card.story} id={card.id} resizable/>
        val = card.current.state? card.current.state.switch: 'n/a';
        control = <MockControl state={val} mqtt={mqtt} id={card.id}/>
        break;
    case 'wdg18':
    case 'watchdog':
        color.s = colors[3];
        color.t = colors[1];
        body = <WatchDog story={card.story} id={card.id} color = {color} task_val={card.task.value} task_id={card.task.var}
                          yAxes= {[{  id: 'flags',
                                      //type: 'linear',
                                      position: 'right',
                                      display:false,
                                      ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 2
                                      }
                                  }, {
                                      id: 'ch3',
                                      type: 'linear',
                                      position: 'left',
                                      ticks: {
                                        suggestedMin: 0,
                                      }},
                                  ]} 
                                  
                          tooltips_callbacks={{
                            label: function(tooltipItem, data) {
                              const val = (data.datasets[tooltipItem.datasetIndex].label==='flags')?tooltipItem.yLabel-1:tooltipItem.yLabel;
                              return data.datasets[tooltipItem.datasetIndex].label +': ' + val;
                            }}} />
        if (card.current && card.current.state)
        {
          val = (<div>
            <i className="fa fa-circle" style={{ color: color.s}} />
            {" flags: " + (card.current.state.flags & 0x1) + "\t"}
            <i className="fa fa-circle" style={{ color: color.t}} />
            {" ch3: " + card.current.state.ch3}
          </div>)
        }
        control = <WatchDogControl state={val} mqtt={mqtt} id={card.id}/>
        break;
    case 'unitxf':
      body = <Office story={card.story} id={card.id} />
      if (card.current && card.current.state)
      {
        val = (<div>
            <i className="fa fa-circle" style={{ color: '#000000' }}/>
            {get_username_from_attrs(card.current.state.user.toString(), card.attrs) + " " + (card.current.state.state?I18n.get("arrived"):I18n.get("left"))}
        </div>)
      }
      control = <OfficeControl card={card} />
      break;
    default:
        break;
  }

  return [body, color, val, control];
}


const DevCard = ({ card, thing_alias, handler, mqtt, plan, ...props }) => {
    const [body, color, val, control] = bodyPreparer(card, mqtt);

    var time = I18n.get(" never.");
    if (card.current && card.current.ts)
    {
      const fromnow = fromnow_local(card.current.ts);
      time = fromnow;
      if (fromnow === 'a few seconds ago')
      {
        time = 'just now';
      }else if  (fromnow === 'несколько секунд назад')
      {
        time = 'только что';
      }
    }
    //
    //const online = (card.current && card.current.online)? true: false;

    return( <Col lg={4}>
              <Card
                title={
                  <div >
                    {(card.alias.length < 18)? card.alias: card.alias.substr(0, 18)+'...'}
                    <div className="pull-right" >
                      <DropdownButton style={{borderWidth: 0, backgroundColor: "#ffffffff", fontSize: "24px", marginBottom: 0 }}  noCaret id="dropdown-custom-1" title={<span className="btn-rotate"><i className="pe-7s-config" /></span>}>
                       {(card.id.startsWith('unitxf') === true)? <OfficeControlReport id={card.id} />: <HistoryDialog id={card.id} title={card.alias} attrs={card.attrs} color = {color}/>}
                        {card.current && card.current.state && <TaskDialog alias={card.alias} task={card.task} vars={Object.keys(card.current.state)} />}
                        <RenameDialog old={card.alias} update={update} type='card'/>
                        <MenuItem divider />
                        <DeleteDialog alias={card.alias} update={update}/>
                      </DropdownButton>
                      </div>
                  </div>
                }
                category={thing_alias}
                content={body}
                legend={
                  <div>
                    {val}
                  </div>
                }
                stats={
                  <div>
                    <i className="fa fa-history" /> {time}
                    <div className="pull-right">
                      {control}
                    </div>
                  </div>
                }
              />
            </Col>
          );
  }


export default(DevCard);

