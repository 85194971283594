import React, { useState } from 'react';

import {error_format} from 'utils';
import axios from 'axios';
import { I18n } from 'I18n.js';
import { update } from 'server';
import {
   FormGroup,
   ControlLabel,
   FormControl,
   Modal
 } from "react-bootstrap";

import { makeid } from 'utils';
import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'

const AddSharedDialog = ({ ...props }) => {
   const { things } = props;
   const [inputs, setInputs] = useState({alias: '', thing: things.length?things[0].id: null})
   const [show, setShow] = useState(false);
   const [msg, setMsg] = useState('');
   const owners = {};

   things.forEach(thing => {
      owners[thing.id] = thing.cond.owner;
    });

   const handleInputChange = e => {
      const {name, value} = e.target
      setInputs({...inputs, [name]: value})
   }

   const toggle = () => {
      setMsg('');
      setInputs({'thing': things.length? things[0].id: null, 'alias': ''})
      setShow(!show);
    }

   const apply = () => {
         axios.post('/thing/ack', {'id' : inputs.thing, 'alias': inputs.alias? inputs.alias: inputs.thing.split('_')[0] +'_'+ makeid(5)})
         .then(() => { setShow(false); update(); })
         .catch((error) => { setMsg(error_format(error)); })
   };
   
   const title = I18n.get('Add the device');

   const body =  things.length?
                <form onSubmit={e => { e.preventDefault(); }}>
                     <FormGroup>
                        <ControlLabel>{I18n.get('Thing')}</ControlLabel>
                        <FormControl componentClass="select" name="thing" onChange={handleInputChange}>
                           { things.map((thing,i) => ( <option key={i}>{thing.alias? thing.alias: thing.id}</option> ))}
                        </FormControl>
                     </FormGroup>
                     <p>{I18n.get('Shared by')}: {owners[inputs.thing]}</p>
                     <FormGroup>
                        <ControlLabel>{I18n.get('Alias')}</ControlLabel>
                        <FormControl type="text" name="alias" placeholder={I18n.get('any name')} defaultValue={inputs.alias} onChange={handleInputChange}/>
                     </FormGroup>
                </form>:
                <p>{I18n.get('Nobody has shared with you yet')} </p>

   const footer = <Modal.Footer>
                     <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
                     {inputs.thing && <Button bsStyle="success" fill onClick={apply}> {I18n.get('Apply')} </Button>}
                  </Modal.Footer>
   return (
      <>
        <Button bsStyle="primary" fill onClick={toggle}>
            {I18n.get('Apply')}
        </Button>
         <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} footer={footer} msg={msg} />
      </>

   );
}

export default AddSharedDialog;