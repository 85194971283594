import React, { useState, useRef, useEffect } from 'react';

import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  FormControl
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import Sidebar from "views/Sidebar.jsx";
//import image from "assets/img/full-screen-image-3.jpg";
import UserNavbar from "views/UserNavbar.jsx";

import axios from 'axios';
import { I18n } from 'I18n.js';
//import { Link } from "react-router-dom";

import RenameDialog from '../components/Dialogs/RenameDialog';

import DeleteDialog from '../components/Dialogs/DeleteDialog';
import AddSharedDialog from 'components/Dialogs/AddSharedDialog';
import DisconnectDialog from 'components/Dialogs/DisconnectThing';
import ShareControlDialog from 'components/Dialogs/ShareControlDialog';
import ToDashDialog from 'components/Dialogs/ToDashDialog';
import RegisterDialog from 'components/Dialogs/RegisterDialog';


import Card from "components/Card.jsx";
import routes from "routes.js";

import {error_format, fromnow_local, description, truncate} from 'utils';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { update } from "server.js"

function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const ThingsList = ({ ...props }) => {
  const [count, setCount] = useState(0);
  const [action, setAction] = useState(I18n.get("Register new"));

  useInterval(() => {
    // SelfRender every 60s
    setCount(count + 1);
  }, 60000);

  const restore_act = (thing_id) =>
  {
    axios.post('/thing/restore', {'id': thing_id})
    .then(() => { toast.info(I18n.get('Success')); update(); }) 
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const things_to_share = [];
  const dataTable = [];
  //var shared_things_total = 0;
  //var shared_things_active = 0;
  //var virtual = 0;
  const things = props.cards_things.things;
  const restore_ids = props.userinfo.restore;
  const email = props.userinfo.user.email;
 
  const things_av = [];
  things.forEach(thing=> {
    if ((thing.cond.owner === null) || (thing.cond.ack !== false))
    {
      things_av.push(thing);
    }
  });

     things.forEach((thing, key) => {
      if ((thing.cond.owner === null) || (thing.cond.ack !== false))
      {
        //if ((thing.cond.owner) && (thing.cond.owner !== email)) {shared_things_total++; if (thing.cond.ack) {shared_things_active++;} }
        const splitted_name = thing.id.split('_');
        //if (splitted_name[0] === 'gateway') {virtual+=1;}

        const row =  <Col lg={12} key={key} >
                      <Card content={<Row>
                                    <Col sm={11}>
                                    <p>{I18n.get('Code')}: {splitted_name[0]}</p>
                                    <p>{I18n.get('Alias')}: {thing.alias}</p>
                                    <p>{I18n.get('Type')}: {description(splitted_name[0])}</p>
                                    <p>{I18n.get('Activity')}: {(thing.ts)? fromnow_local(thing.ts): I18n.get('never')}</p>
                                    {(thing.cond.owner === email)? <p>{I18n.get('Access') + ': ' + I18n.get('Shared')}</p>: (thing.cond && thing.cond.ack)? <p>{I18n.get('Access') + ': ' + I18n.get('Shared by') + ' ' + thing.cond.owner}</p>: null}
                                    <p>{'ID: ' + truncate(thing.id, 32)}</p>  
                                    </Col>
                                    <Col sm={1}>
                                    <span className="text-right"> 
                                     <ToDashDialog id={thing.id} alias={thing.alias}/> 
                                     <RenameDialog update={update} type='thing' old={thing.alias} id={thing.id} /> 
                                    {(thing.cond.owner === null || thing.cond.owner === email) && <ShareControlDialog alias={thing.alias} id={thing.id} /> }
                                    {(thing.cond && thing.cond.ack)?<DisconnectDialog id={thing.id}/>: <DeleteDialog deletething thing_id={thing.id} update={update} />}
                                    </span>
                                    </Col>
                                  </Row>}
                        />
                    </Col>
        dataTable.push(row);
      }else
      {
        //shared_things_total++;
        if (thing.cond.ack === false) {things_to_share.push(thing);}
      }
    });

    restore_ids.forEach((id, key) => {
      const splitted_name = id.split('_');
      const row = <Col lg={12} key={key} >
                     <Card title={splitted_name[0]}
                        category={<p className="text-danger">{I18n.get('BLOCKED')}</p>}
                        content={<Row>
                                  <Col sm={11}>
                                    <p>{I18n.get('Type')}: {description(splitted_name[0])}</p>
                                    <p>{'ID: ' + truncate(id,32)}</p>
                                  </Col>
                                  <Col sm={1}>
                                  <span className="text-right">
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="restore">{I18n.get('Restore')}</Tooltip>}>
                                      <Button simple bsStyle="success" bsSize="lg" style={{padding: '16px 24px'}} onClick={()=>{restore_act(id)}}>
                                          <i className="pe-7s-refresh" />
                                      </Button>
                                    </OverlayTrigger>
                         
                                    <DeleteDialog deletething thing_id={id} update={update} />  
                                  </span>      
                                  </Col>
                                </Row>}
                    />
                    </Col>
      dataTable.push(row);
    });
   
   
    return (
      <div className="wrapper">
        <Sidebar routes={routes} color={"gray"} hasImage={false} mini={true} />
        <div className={"main-panel"}>
          <UserNavbar handleMiniClick={()=>{document.body.classList.toggle("sidebar-mini")}} navbar={false} /> 

          <div className="main-content">
            <Grid fluid>
            <Row>
            {dataTable}
            <Col lg={12}>
            <Card 
              title={I18n.get('New device')}
              content={ <Row>
                <Col xs={7}>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <FormControl componentClass="select" onChange={(e)=>{setAction(e.target.value)}} defaultValue={action}>
                      <option>{I18n.get("Register new")}</option>
                      <option>{I18n.get("Conneсt shared")}</option>
                    </FormControl>
                </form>
                </Col>
                <Col xs={5}>
                  {(action === I18n.get("Register new"))?<RegisterDialog />: <AddSharedDialog things={things_to_share}/>}
                </Col>
              </Row>
              }
            /> 
            </Col>
            </Row>
            </Grid>
          </div>
        </div>
      </div>
    );

}

const mapStateToProps = function(store) {
  return {
    userinfo: store.userinfo,
    cards_things: store.cards_things
  };
}

export default connect(mapStateToProps)(ThingsList);
