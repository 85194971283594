import React from "react";
import ReactDOM from "react-dom";
import { Router, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import 'assets/fonts/oswald.woff';
import './app.css';

//import { Auth0Provider, useAuth0 } from "components/Auth0/react-auth0-spa";
import config from "components/Auth0/auth_config.json";
import history from 'components/Auth0/history';
import Loading from 'components/Auth0/Loading';
import ExternalApi from "components/Auth0/ExternalApi";
import PrivateRoute from "components/Auth0/PrivateRoute";
import { clearApiToken } from 'server';

import {  Provider } from 'react-redux';
import { store } from 'redux-utils';

import Dashboard from "views/Dashboard";
import UserProfile from "views/UserProfile";
import Login from "views/Login";
import Things from "views/Things";
import Verify from 'views/Verify';
import VerifyOK from 'views/VerifyOK'
import LockPlan from 'views/LockPlan';
import Readmessages from 'views/ReadMessages';
import {PaySuccess, PayFail} from 'components/Dialogs/PayDialogs';
import { Route } from "react-router-dom";


const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

window.hp = history.push;

const Logout = () => {
 // const { logout } = useAuth0();
  clearApiToken();
  if (window.ReactNativeWebView)
  {
    window.ReactNativeWebView.postMessage( JSON.stringify({new_mail: 0, path: '/logout'}) );
  }
  //logout();
  window.hp('/');
  return (<div></div>);
}

const App = () => {
  //const { loading } = useAuth0();
  
 // if (loading) {
 //   return <Loading />;
 // }

  return (
    <Router history={history}>
       <ToastContainer position="top-right"
                              autoClose={4000}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnVisibilityChange
                              draggable
                              pauseOnHover/>
      <Switch> 

        <Route path="/verify" render={Verify} />;
        <Route path="/verifyok" render={VerifyOK} />;  
        <Route path="/paysuccess" render={PaySuccess} />;
        <Route path="/payfail" render={PayFail} />;
        <Route path="/logout" render={Logout} />;
  {/*
        <PrivateRoute path="/things" component={ExternalApi} params={Things}/>
        <PrivateRoute path="/profile" component={ExternalApi} params={UserProfile}/>
        <PrivateRoute path="/readmsg" component={ExternalApi} params={Readmessages}/>
        
        
      <PrivateRoute path="/dashboard" component={ExternalApi} params={Dashboard}/>
        <PrivateRoute path="/logout" component={ExternalApi} params={Logout}/>
        <PrivateRoute path="/lockplan" component={ExternalApi} params={LockPlan}/>
        */}
        
        <Route path="/things" render={() => <ExternalApi component={Things} /> } />
        <Route path="/profile" render={() => <ExternalApi component={UserProfile} /> } />
        <Route path="/readmsg" render={() => <ExternalApi component={Readmessages} /> } />

        <Route path="/lockplan" render={() => <ExternalApi component={LockPlan} /> } />
        <Route path="/dashboard" render={() => <ExternalApi component={Dashboard} /> } />
        <Route path="/" render={Login} />;

      </Switch>
            
    </Router>
  )

};




ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
