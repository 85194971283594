
import React, { useState, useEffect, Component } from 'react';

import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import bgImage from "assets/img/bg1.jpg";
import { I18n } from 'I18n.js';
import {URL_PRICES, URL_SUPPORT, URL_DOCUMENTS, locale} from 'server';

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import BaseCard from "components/BaseCard.jsx";
import Button from "components/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox.jsx";

import { setApiToken } from "server.js"


function LoginForm() {
  const [cardHidden, setCardHidden] = useState(true);
  const [terms, setTerms] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setTimeout(
      function() {
        setCardHidden(false)
      }.bind(this),
      700
    );
  }, []);

    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <BaseCard
                hidden={cardHidden}
                textCenter
                title={I18n.get('LogIn')}
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email</ControlLabel>
                      <FormControl placeholder="" type="email" defaultValue={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>{I18n.get('Password')}</ControlLabel>
                      <FormControl placeholder="" type="password" autoComplete="off" defaultValue={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                    </FormGroup>
                    <FormGroup>
                      <Checkbox number="1" label={<div> {I18n.get('I agree with ')} <a href={URL_DOCUMENTS} rel="noopener noreferrer" target="_blank">{I18n.get('privacy & terms')}</a></div>} checked={terms} onChange={() => {setTerms(!terms)}} />
                    </FormGroup>
                  </div>
                }
                legend={
                  terms ?
                  <NavLink to={"/dashboard"} className="nav-link">
                    <Button bsStyle="info" fill wd onClick={()=> {
                        setApiToken(email, password);
                    }}>
                    {I18n.get('LogIn')}
                    </Button>
                  </NavLink>:
                    <Button bsStyle="default" fill wd >
                       {I18n.get('LogIn')}
                    </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );

}

class PagesHeader extends Component {
    constructor(props) {
      super(props);
      this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
      this.state = {
        width: window.innerWidth
      };
    }
    // function that sets the class to active of the active page
    activeRoute(routeName) {
      return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }
    // function that shows/hides sidebar on responsive
    mobileSidebarToggle(e) {
      document.documentElement.classList.toggle("nav-open");
    }
    updateWidth() {
      this.setState({ width: window.innerWidth });
    }
    componentDidMount() {
      window.addEventListener("resize", this.updateWidth.bind(this));
    }
    render() {
      return (
        <Navbar
          collapseOnSelect
          inverse
          className="navbar-primary navbar-transparent navbar-absolute"
        >
          <Navbar.Header>
            <Navbar.Brand>
              <NavLink to={"/dashboard"} className="nav-link">
                UnitX Cloud
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle onClick={this.mobileSidebarToggle} />
          </Navbar.Header>
          <Navbar.Collapse>
            <ul className="nav navbar-nav navbar-right">
              <li>
                <NavLink to={"/"} className="nav-link" onClick={()=>window.open(URL_PRICES, "_blank")}>
                  <i className="fa fa-drivers-license-o" />
                  <p>{I18n.get('Pricing')}</p>
                </NavLink>
              </li>
              <li className={this.activeRoute("register-page")}>
                <NavLink to={"/"} className="nav-link" onClick={()=>window.open(URL_DOCUMENTS, "_blank")}>
                  <i className="fa fa-th-list" />
                  <p>{I18n.get('Documents')}</p>
                </NavLink>
              </li>
            </ul>
          </Navbar.Collapse>
        </Navbar>
      );
    }
  }



class Footer extends Component {
  render() {
    return (
      <footer
        className={
          "footer" +
          (this.props.transparent !== undefined ? " footer-transparent" : "")
        }
      >
        <div
          className={
            "container" + (this.props.fluid !== undefined ? "-fluid" : "")
          }
        >
         
          <p>
            В связи с санкционной блокировкой провайдером аутентификации Auth0 сервиса UnitX Cloud, регистрация новых пользователей прекращена, пароли сброшены. Если вы не получили свой пароль на e-mail, обратитесь, пожалуйста, с запросом на main@open-dev.ru 
          </p>
        </div>
      </footer>
    );
  }
}

  
const Login = ({ ...props }) => {
    //componentWillMount() {
    //  if (document.documentElement.className.indexOf("nav-open") !== -1) {
    //    document.documentElement.classList.toggle("nav-open");
    //  }
    //}
     const lang = locale.startsWith('ru')?'ru':'en';
     I18n.setlang(lang);

      return (
        <div>
          <PagesHeader />
          <div className="wrapper wrapper-full-page">
          <div
            className={"full-page login-page"}
            data-color="black"
            data-image={bgImage}
          >
            <div className="content">
              <LoginForm/>
            </div>
            <Footer transparent />
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>

        </div>
     
        </div>
      );
  }
  
export default Login;
