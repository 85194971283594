import React, { useState, useEffect, useRef } from 'react';
import { Grid, Row } from "react-bootstrap";

import Sidebar from "views/Sidebar.jsx";
//import image from "assets/img/full-screen-image-3.jpg";
import UserNavbar from "views/UserNavbar.jsx";
import routes from "routes.js";
import DevCard from "components/DevCard";
import {get_thing_alias} from 'utils';
import { connect } from 'react-redux';
import { I18n } from 'I18n.js';
import { update } from "server.js"
import { isMobile } from 'react-device-detect';

function useInterval(callback, delay) {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  

const Dashboard = ({ ...props }) =>  {
  const [count, setCount] = useState(0);

  useInterval(() => {
    // SelfRender every 60s
    setCount(count + 1);
  }, 60000);

  const onFocus = () =>
  {
    if (isMobile) { update(); }  //only for mobile
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  });

  return (
    <div className="wrapper">
      <Sidebar routes={routes} color={"gray"} hasImage={false} mini={true} />
      <div className={"main-panel"}>
        <UserNavbar handleMiniClick={()=>{document.body.classList.toggle("sidebar-mini")}} navbar={false} /> 
        <div className="main-content">
          <Grid fluid>
            <Row>
            { (props.cards_things.cards.length>0)? props.cards_things.cards.map(function(card, i) { 
              return(<DevCard card={card} thing_alias={get_thing_alias(props.cards_things.things, card.id)} key={i}/>)
              })
              :
              <Row  style={{display: "flex", justifyContent: "center"}}>
                <h5 className="text-muted">{I18n.get('Empty now')}</h5>
              </Row>
            }
            </Row>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = function(store) {
  return {
    cards_things: store.cards_things,
  };
}

export default connect(mapStateToProps)(Dashboard);


