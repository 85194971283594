import React, { useState } from 'react';

import { I18n } from 'I18n.js';
import { Table, Modal, Row, Col, FormGroup,  OverlayTrigger, Tooltip, MenuItem } from 'react-bootstrap';
import Button from "components/CustomButton.jsx";
import Datetime from "react-datetime";
import axios from 'axios';
import { local_to_utc_ts, error_format, yesterday, secondsToString } from 'utils';
import { toast } from 'react-toastify';
var fileDownload = require('js-file-download');

const OfficeControlReport = ({ ...props }) =>  {
  const { id, onSelect } = props;

  const [show, setShow] = useState(false);
  const [from, setFrom] = useState(yesterday);
  const [to, setTo] = useState(new Date());
  const [title, setTitle] = useState(I18n.get('Report'));
  const [table_body, setTable_body] = useState(null);

  const toggle = () => {
    if (!show) {gethistory(from, to);}
    setShow(!show);
  }

  const download = () =>
  {
    axios.get('/call', {headers: {'Accept':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}, params: {'id': id, 'from': local_to_utc_ts(from.setHours(3, 0, 0)), 'to': local_to_utc_ts(to), 'group': 'unitxf' }, responseType: 'blob'})
    .then((res) => {
      //const data = JSON.parse(res);
      //console.log(res);
      fileDownload(res.data, title + '.xlsx'); 
      }).catch((error) => {  toast.error(error_format(error)) })
  }

const gethistory = (l_from, l_to) => {
    axios.get('/call', { params: {'id': id, 'from': local_to_utc_ts(l_from.setHours(3, 0, 0)), 'to': local_to_utc_ts(l_to), 'group': 'unitxf' }})
    .then((res) => {
      const resp = JSON.parse(res.request.response);
      //console.log(resp);
      setTitle(resp.title);
      const tbl = [];

      resp.summary.forEach((item, i)=> {
        tbl.push(
            <tr className={"info"} key={i}>
                <td>{item.day}</td>
                <td></td>
            </tr>);

        tbl.push(
            Object.keys(resp.legend).map((key,i) => 
                        <tr key={100+i}>
                            <td>{key}</td>
                            <td>{(item[parseInt(resp.legend[key], 10)] === '-1') ?  <OverlayTrigger placement="right" overlay={<Tooltip id="user_error"> {I18n.get('violation')}</Tooltip>}><i className="pe-7s-attention" /></OverlayTrigger>:
                                                                                    secondsToString((item[parseInt(resp.legend[key], 10)] || 0) * 60)}</td>
                        </tr>
            )
        )

      });

      if (tbl.length>0)
      {
        setTable_body(
            <Table responsive>
                    <thead>
                    <tr>
                    <th>{I18n.get('Name')}</th>
                    <th>{I18n.get('Time')}</th>
                    </tr>
                </thead>
                <tbody>
                {tbl}
                </tbody>
            </Table>

        );
      }else
      {
        setTable_body(<p>{I18n.get('Nobody')}</p>);
      }
      
    }).catch((error) => {  toast.error(error_format(error)) })
}

      
  const body = <div>
                    {table_body}                     
                    <hr/>
                                <Row>
                                    <Col md={4}>
                                    {I18n.get('From')}
                                    <FormGroup>
                                        <Datetime
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Date Picker Here" }}
                                        defaultValue={from}
                                        onChange={(mom)=>{setFrom(mom.toDate()); gethistory(mom.toDate(), to); }}
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                    {I18n.get('To')}
                                    <FormGroup>
                                        <Datetime
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Date Picker Here" }}
                                        defaultValue={to}
                                        onChange={(mom)=>{setTo(mom.toDate()); gethistory(from, mom.toDate()); }}
                                        />
                                    </FormGroup>
                                    </Col>
                                </Row>   
                </div>

  
  return ( <>

          <MenuItem onSelect={onSelect} onClick={toggle}> <i className="pe-7s-graph3" /> {I18n.get('History')} </MenuItem>
            <Modal show={show} onHide={() => setShow(false)} dialogClassName="custom-modal">
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{body}</Modal.Body>
              <Modal.Footer>
                <Button simple onClick={download} bsStyle="primary"> {I18n.get('Export')} </Button>
                <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
              </Modal.Footer>
      
            </Modal>
          </>

          );
               

}
export default OfficeControlReport;