import React, { useState } from 'react';

import {error_format} from 'utils';
import axios from 'axios';
import { I18n } from 'I18n.js';
import { update } from 'server';
import {
   FormGroup,
   ControlLabel,
   FormControl,
   Modal
 } from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import { makeid } from 'utils';
import { toast } from 'react-toastify';

const RegisterDialog = ({ ...props }) => {
   const [code, setCode] = useState('');
   const [show, setShow] = useState(false);
   const [msg, setMsg] = useState('');
   const [id, setId] = useState('');
   const [alias, setAlias] = useState('');

   const toggle = () => {
    setShow(!show);
    setMsg('');
    setCode('');
    setId('');
  }

  const apply = () =>
  {
    axios.post('/info', { 'type' : 'otp', 'code': code} )
    .then((res) => {
       update(); const data = JSON.parse(res.request.response);
       //console.log(data);
       const dev_name = data.alias? data.alias: data.id.split('_')[0] +'_'+ makeid(5)
       setMsg(dev_name+ ' ' + I18n.get('added')); 
       setAlias(dev_name);
       setId(data.id);
      })
    .catch((error) => { setMsg(error_format(error)); })
  }

  const add_to_dash = (id, alias) =>
  {
    axios.post('/new/card', {'id': id, 'alias': alias})
    .then(() => { setShow(false); toast.info(I18n.get('The card is added') + ': ' + alias); update(); })
    .catch((error) => {  toast.error(error_format(error)) })
  }

  const title =I18n.get('Register a new device');
  const body = <form onSubmit={e => { e.preventDefault(); }}>
                <FormGroup>
                  <ControlLabel> {I18n.get('Enter a device code')}</ControlLabel>
                  <FormControl defaultValue={code} onChange={(e) => {setCode(e.target.value)}} />
                </FormGroup>
              </form>

  return (
    <>
        <Button bsStyle="primary" fill onClick={toggle}>
            {I18n.get('Apply')}
        </Button>

        <Modal show={show} onHide={toggle}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {body}
            {msg &&
                  <blockquote className="blockquote blockquote-danger">
                      <p className="text-danger" align="center">{msg}</p>
                  </blockquote>
                }
          </Modal.Body>
          <Modal.Footer>
            <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
            {(code && (id === '')) && <Button bsStyle="success" fill onClick={apply}> {I18n.get('Apply')} </Button>}

            { id && <Button bsStyle="success" fill onClick={()=>add_to_dash(id, alias)}> {I18n.get('To the dashboard')}</Button>}
            {/*id && <ToDashDialog id={id} alias={alias} dialog/>*/}
          </Modal.Footer>
        </Modal>
    </>
  );
}

export default RegisterDialog;