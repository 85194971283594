import moment from 'moment';
import { I18n } from 'I18n.js';
//import { NavLink } from 'react-router-dom';

export const error_format = (error) => {
    var err = error;
    //console.log(error.response.data.error);
    try {
        if(error.response.status >= 300) {
            err =  error.response.data.error? error.response.data.error: error.response.statusText;
        }
        return err;
      } catch (err) {
        return 'Network Error';
      }
  }
  
export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

//-----------momentjs------------
export const localtime = (ts) => {
    return moment.unix(ts);
}

export const fromnow_local = (ts, par) => {
    //return localtime(ts).subtract(10, 'seconds').fromNow(par);    
    return moment.min(localtime(ts), moment()).fromNow(par);
}

export const tonow_local = (ts) => {
    return localtime(ts).toNow();
}

export const daysago_local = (days_ago) => {
    return moment().subtract(days_ago, 'days').unix();
}

export const local_to_utc_ts = (time) => {
    return moment(time).unix();
}

export const yesterday = () => 
{
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
}

export const yesterday_3am = () => 
{
  const date = new Date();
  date.setDate(date.getDate() - 1);
  date.setHours(3, 0, 0);
  return date;
}

//---------------------------------

export function objToString (obj) {
    var str = '';
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            str += p + ':' + obj[p] + '; ';
        }
    }
    return str;
}

export const get_thing_alias = (things, id) => {
 
    for (var item in things) {
        if (things[item].id === id) {return (things[item].alias);} 
    }; 
    return '';
}

export function copyToClipboard(text){
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

export function copyFromClipboard(){
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.select();
    document.execCommand("paste");
    console.log(dummy);
    const res = dummy.value;
    document.body.removeChild(dummy);
    return res;
}


export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
  
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  
export const colors = [
    '#242321',
    '#75C8CC',
    '#F2C76B',
    '#2400B0',
    '#5199FF',
    '#D2AA1B',
    '#5BFF62',
    '#BEF761',
    '#FFD600',
    '#FBE7B5',
    '#FFC46B',
    '#FF6B00',
    '#FFDFDC',
    '#F6522E',
    '#EF2FA2',
    '#782FEF',
    '#231F20',
    
    
    '#0351C1',
    '#51EAFF',
    '#45D09E',
    '#FF7272',
    '#116315',
    '#A7E541',
    '#B40A1B',
    '#FFE55E',
    '#FFE0BC',
    '#FF905A',
    '#922D25',
    '#FF9CA1',
    '#E20338',
    '#E47CCD',
    '#A771FE',
    '#FD0079',
    '#6D6E71',
    '#D1D3D4',
    
    '#052555',
    '#D7FFFE',
    '#BDCCFF',
    '#A854A5',
    '#64C7FF',
    '#17F1D7',
    '#7C3668',
    '#00848C',
    '#4D8802',
    '#1E3C00',
    '#8EAF0C',
    '#748700',
    '#FFF851',
    
    '#D6C21A',
    '#FE9E76',
    '#FEAC92',
    '#E85668',
    '#460000',
    '#B10361',
    '#810B44',
    '#FFBEED',
    '#852EBA',
    '#E4FFF9']


const desc_data = {
    'unitxt': 'Temperature sensor', 
    'odtemp1u': 'Temperature sensor', 
    'odtemp2t' : 'Temperature sensor',
    'unitxh': 'Temprature/Humidity sensor',
    'unitxd': 'Temprature/Humidity sensor',
    'odtemp1w': 'Temprature/Humidity sensor',
    'odtemp2h': 'Temprature/Humidity sensor',
    'unitxc': 'CO2 sensor',
    'gateway': 'PC gateway',
    'watchdog': 'WatchDog',
    'skirnir': 'Notifier',
    'unitxf': 'Fingerprint sensor',
}

export const description = (text) => {
    return I18n.get(desc_data[text]);
}

export const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

export function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
      
export function prepareContentForBar(s) {
    const max_len = 60;
    const plaintext = extractContent(s);
    return (plaintext.length<(max_len-2))? plaintext: plaintext.substr(0, max_len-2)+'...';
  };

export function truncate( str, n, useWordBoundary ){
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary 
      ? subString.substr(0, subString.lastIndexOf(" ")) 
      : subString + '...'); //use &hellip; if result size is important
  };

export function get_username_from_attrs(code, attrs){
    return attrs['user' + code]
}

export function secondsToString(seconds) {
    if (seconds === 0) return 0;

    var numminutes = 0, numhours = 0, numdays = 0, numyears = 0;
    var numseconds = (seconds % 60); seconds = Math.floor(seconds / 60);
    if(seconds) {
        numminutes = (seconds % 60); seconds = Math.floor(seconds / 60);
        if(seconds) {
            numhours = (seconds % 24); seconds = Math.floor(seconds / 24);
            if(seconds) {
                numdays = (seconds % 365);
                numyears = Math.floor(seconds / 365);
            }
        }
    }
    var tstr = '';
    if(numyears) {
        tstr += numyears + I18n.get('y') + ' ';
    }
    if(numdays) {
        tstr +=  numdays + I18n.get('d') + ' ';
    }
    if(numhours) {
        tstr +=  numhours + I18n.get('h') + ' ';
    }
    if(numminutes) {
        tstr +=  numminutes + I18n.get('m') + ' ';
    }
    if(numseconds) {
        tstr +=  numseconds + I18n.get('s');
    }
    // console.log(to ${tstr});
    return tstr.trim();
}