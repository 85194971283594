import React, { useState } from 'react';
import axios from 'axios';
import {error_format} from 'utils';
import { I18n } from 'I18n.js';
import { update } from 'server';
import BaseDialog from './BaseDialog'

import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";

const DisconnectDialog = ({ ...props }) => {
  const {id} = props;

  const title = I18n.get('Disconnect the shared device');
  const body = props.deletething? I18n.get('Are you sure?'): I18n.get('Are you sure? You can add it again later.')

  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');

  const toggle = () => {
    setShow(!show);
    setMsg('');
  }

  const apply = () =>
  {
    axios.post('/thing/reject', {'id': id})
    .then(() => { update(); setShow(false); })
    .catch((error) => { setMsg(error_format(error)); })
  }

  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip id="disconnect">{I18n.get('Disconnect')}</Tooltip>}>
        <Button simple bsStyle="danger" style={{padding: '16px 24px'}} bsSize="lg" onClick={toggle}>
          <i className="fa fa-times" />
        </Button>
      </OverlayTrigger> 
      <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} apply_cb={apply} msg={msg} />
    </>
  );

}

export default DisconnectDialog;