import React, { useState } from 'react';
//import {Button,  Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, Input, Label, FormGroup } from 'reactstrap';
import { Modal, MenuItem, FormGroup, Row, Col } from "react-bootstrap";
import Button from "components/CustomButton.jsx";
import Datetime from "react-datetime";

import {Mock, ODTemp, Office, WatchDog, UnitXC} from '../Devices/devices.jsx';

import { CSVLink } from "react-csv";

import axios from 'axios';
import { local_to_utc_ts, localtime, objToString, error_format, yesterday } from 'utils';
import { I18n } from 'I18n.js';
import { toast } from 'react-toastify';


const HistoryDialog = ({ ...props }) => {
  const { id, color, title, onSelect, attrs } = props;

  const [show, setShow] = useState(false);
  const [graph, setGraph] = useState(null);
  const [cvs_data, setCvs_data] = useState(null);
  const [from, setFrom] = useState(yesterday);
  const [to, setTo] = useState(new Date());
  
  const toggle = () => {
    if (!show) {gethistory(from, to);}
    setShow(!show);
  }

  const gethistory = (l_from, l_to) => {
    //console.log('add datechecker');
    //console.log(l_from, l_to);
    //console.log(local_to_utc_ts(l_from), local_to_utc_ts(l_to));
    //const step = {'1':60, '7':420, '30':3600, '90':5400, '180':16200}
    axios.get('/history', { params: {'id': id, 'from': local_to_utc_ts(l_from), 'to': local_to_utc_ts(l_to), 'max': 200 }})
    .then((res) => {
      const raw_story = JSON.parse(res.request.response);
      const type = id.split('_')[0];
      
      const story = raw_story.map(function(element)
      {
          return {ts: element.ts, state:element}
      });
      //console.log(story);

      if (story.length)
      {
        switch (type)
        {
          case 'odtemp1u':
          case 'odtemp2t':
          case 'unitxt':
            setGraph(<ODTemp story={story} color={color} resizable/>)
            break;
          case 'odtemp2h':
          case 'unitxh':
          case 'unitxtempu':
          case 'unitxd':
              setGraph(<ODTemp story={story} color={color} yAxes={[{id: 't', position: 'left',
              ticks: {suggestedMin: 0}}, 
              {id: 'h', position: 'right',
              ticks: {suggestedMin: 0}}]}  resizable/>);
              break;
          case 'unitxc':
                setGraph(<UnitXC story={story} color={color} resizable/>);
                break;
          case 'mockthing':
              setGraph(<Mock story={story} resizable/>);
              break;
          case 'wdg18':
          case 'watchdog':
              setGraph(<WatchDog story={story} color = {color} resizable yAxes= {[{  id: 'flags',
                                          type: 'linear',
                                          position: 'right',
                                          display:false,
                                          ticks: {
                                            suggestedMin: 0,
                                            suggestedMax: 2
                                          }
                                      }, {
                                          id: 'ch3',
                                          type: 'linear',
                                          position: 'left',
                                          ticks: {
                                            min: 0,
                                          }
                                      }]}
                                      tooltips_callbacks={{
                                        label: function(tooltipItem, data) {
                                          const val = (data.datasets[tooltipItem.datasetIndex].label==='flags')?tooltipItem.yLabel-1:tooltipItem.yLabel;
                                          return data.datasets[tooltipItem.datasetIndex].label +': ' + val;
                                        }}}
                                      />);
              break; 
            case 'unitxf':
               setGraph(<Office story={story} id={id} attrs={attrs} resizable yAxes={[{display:false}]}
                  tooltips_callbacks={{
                  label: function(tooltipItem, data) {
                      if (tooltipItem.yLabel)
                      {
                        return data.datasets[tooltipItem.datasetIndex].label;
                      }
                  }}}
                />)
                break;
          default:
              break;
        }
        const cvs_data = story.map(function(element)
        {
            return [localtime(element.ts).format('HH:mm DD-MMMM-YYYY'), objToString(element.state)];
        });
        setCvs_data(cvs_data);
      }else
      {
        setGraph(<p>{I18n.get('No data, try to change a period')}</p>);
      }

    }).catch((error) => {  toast.error(error_format(error)) })
  }

  const body = <div>
                  {graph}
                  <hr/>
                  <Row>
                    <Col md={4}>
                      {I18n.get('From')}
                      <FormGroup>
                        <Datetime
                          timeFormat={false}
                          inputProps={{ placeholder: "Date Picker Here" }}
                          defaultValue={from}
                          onChange={(mom)=>{ if (typeof mom !== "string") { setFrom(mom.toDate()); gethistory(mom.toDate(), to); }}}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      {I18n.get('To')}
                      <FormGroup>
                        <Datetime
                          timeFormat={false}
                          inputProps={{ placeholder: "Date Picker Here" }}
                          defaultValue={to}
                          onChange={(mom)=>{ if (typeof mom !== "string") {setTo(mom.toDate()); gethistory(from, mom.toDate()); }}}
                        />
                      </FormGroup>
                    </Col>
                  </Row>   
               </div>
  
  return (
    <>
      <MenuItem onSelect={onSelect} onClick={toggle}> <i className="pe-7s-graph3" /> {I18n.get('History')} </MenuItem>

      <Modal show={show} onHide={() => setShow(false)} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          {cvs_data &&
              <CSVLink data={cvs_data} separator=";" filename={title + "_report.csv"}>
                  <Button simple onClick={toggle} bsStyle="primary"> {I18n.get('Export')} </Button>
              </CSVLink>
          }
          <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
         
        </Modal.Footer>

      </Modal>
    </>
  );

}

export default HistoryDialog;