var dateFormat = require('dateformat')
var rubles = require('rubles').rubles;
var numeral = require('numeral')

export const pdfTemplate = ({ name, inn, kpp, price, billNumber }) => {
    const mycompany = {name: 'ООО "Открытые разработки"', inn: '7729455841', kpp: '772601001', rs:'40702810402500073817', bik: '044525999', ks: '30101810845250000999', bank: 'ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ" г. Москва', director: 'Панюшкин В.А.'}
    const curDate = dateFormat(new Date(), 'dd.mm.yyyy');
    const service = {name:'Услуги "Unitx Cloud"', amount: 1, price: price};
    //const countSum = price;
    const totalSum = price;
    const rub = rubles(totalSum);
    const formatMoney = numeral(price).format('0,0.00');
    console.log(kpp==="", kpp === null );
    const kpp_str = (kpp !== "")? ", КПП: " + kpp: "" 
    //{data: req.body, curDate: curDate, billNumber: '' + billNumber, config: config}
    return `<!doctype html>
        <html>
        <head>
        <title>Бланк "Счет на оплату"</title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <style>
        body { width: 210mm; margin-left: auto; margin-right: auto; padding-left: 7mm; padding-right: 7mm; padding-top: 10mm; font-size: 11pt; margin-top: 10mm;}
        table.invoice_bank_rekv { border-collapse: collapse; border: 1px solid; }
        table.invoice_bank_rekv > tbody > tr > td, table.invoice_bank_rekv > tr > td { border: 1px solid; }
        table.invoice_items { border: 1px solid; border-collapse: collapse;}
        table.invoice_items td, table.invoice_items th { border: 1px solid;}
        </style>
        </head>
        <body>
        <table width="100%">
            <tr>
            <td colspan="2">
                <div style="text-align:center;  font-weight:bold;">
                Образец заполнения платежного поручения
                </div>
            </td>
            </tr>
        </table>
        
        <table width="100%" cellpadding="2" cellspacing="2" class="invoice_bank_rekv">
            <tr>
            <td colspan="2" rowspan="2" style="min-height:13mm; width: 105mm;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="height: 13mm;">
                <tr>
                    <td valign="top">
                    <div>${mycompany.bank}</div>
                    </td>
                </tr>
                <tr>
                    <td valign="bottom" style="height: 3mm;">
                    <div style="font-size:10pt;">Банк получателя</div>
                    </td>
                </tr>
                </table>
            </td>
            <td style="min-height:7mm;height:auto; width: 35mm;">
                <div>БИK</div>
            </td>
            <td rowspan="2" style="vertical-align: top; width: 60mm;">
                <div style=" height: 7mm; line-height: 7mm; vertical-align: middle;">${mycompany.bik}</div>
                <div>${mycompany.ks}</div>
            </td>
            </tr>
            <tr>
            <td style="width: 25mm;">
                <div>Счет банка</div>
            </td>
            </tr>
            <tr>
            <td style="min-height:6mm; height:auto; width: 50mm;">
                <div>ИНН ${mycompany.inn}</div>
            </td>
            <td style="min-height:6mm; height:auto; width: 55mm;">
                <div>КПП ${mycompany.kpp}</div>
            </td>
            <td rowspan="2" style="min-height:19mm; height:auto; vertical-align: top; width: 25mm;">
                <div>Расчетный счет</div>
            </td>
            <td rowspan="2" style="min-height:19mm; height:auto; vertical-align: top; width: 60mm;">
                <div>${mycompany.rs}</div>
            </td>
            </tr>
            <tr>
            <td colspan="2" style="min-height:13mm; height:auto;">
        
                <table border="0" cellpadding="0" cellspacing="0" style="height: 13mm; width: 105mm;">
                <tr>
                    <td valign="top">
                    <div>${mycompany.name}</div>
                    </td>
                </tr>
                <tr>
                    <td valign="bottom" style="height: 3mm;">
                    <div style="font-size: 10pt;">Получатель</div>
                    </td>
                </tr>
                </table>
        
            </td>
            </tr>
        </table>
        <br/>
        
        <div style="font-weight: bold; font-size: 16pt; padding-left:5px; margin-top: 7mm; margin-bottom: 2mm;">
            Счет на оплату № ${billNumber} от ${curDate}
        </div>
        
        <div style="background-color:#000000; width:100%; font-size:1px; height:2px; margin-bottom: 7mm;">&nbsp;</div>
        
        <table width="100%">
            <tr>
            <td style="width: 30mm;">
                <div style=" padding-left:2px;">Исполнитель:</div>
            </td>
            <td>
                <div style="font-weight:bold;  padding-left:2px;">
                ${mycompany.name}, ИНН: ${mycompany.inn}, КПП: ${mycompany.kpp}
                </div>
            </td>
            </tr>
            <tr>
            <td style="width: 30mm;">
                <div style=" padding-left:2px;">Заказчик:</div>
            </td>
            <td>
                <div style="font-weight:bold;  padding-left:2px;">
                ${name}, ИНН: ${inn}${kpp_str}
                </div>
            </td>
            </tr>
        </table>
        
        
        <table class="invoice_items" width="100%" cellpadding="2" cellspacing="2" style="margin-top: 7mm; margin-bottom: 3mm;">
            <thead>
            <tr>
                <th style="width:13mm;">№</th>
                <th>Название товара или услуги</th>
                <th style="width:20mm;">Кол-во</th>
                <th style="width:27mm;">Цена</th>
                <th style="width:27mm;">Сумма</th>
            </tr>
            </thead>
            <tbody >

                <tr style="text-align: center;">
                <td>1</td>
                <td style="text-align: left; padding-left: 7px;">${service.name}</td>
                <td>${service.amount}</td>
                <td>${formatMoney} р.</td>
                <td>${formatMoney} р.</td>
                </tr>

            </tbody>
        </table>
        
        <table border="0" width="100%" cellpadding="1" cellspacing="1">
            <tr>
            <td></td>
            <td style="width:50mm; font-weight:bold;  text-align:right;">Итого:</td>
            <td style="width:50mm; font-weight:bold;  text-align:right;">${formatMoney} руб.</td>
            </tr>
            <tr>
            <td></td>
            <td style="width:50mm; font-weight:bold;  text-align:right;">В том числе НДС:</td>
            <td style="width:50mm; font-weight:bold;  text-align:right;">Без НДС</td>
            </tr>
            <tr>
            <td></td>
            <td style="width:50mm; font-weight:bold;  text-align:right;">Всего к оплате:</td>
            <td style="width:50mm; font-weight:bold;  text-align:right;">${formatMoney} руб.</td>
            </tr>
        </table>
        
        <br />
        <div>
            Всего наименований 1 на сумму ${rub}<br /><br/>
        </div>
        <div style="background-color:#000000; width:100%; font-size:1px; height:2px; margin-bottom: 14mm;">&nbsp;</div>
        
        <div>Генеральный директор ______________________ (${mycompany.director})</div>
        <br/>
        
        <div style="width: 85mm;text-align:center;">М.П.</div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        Настоящий счет («Счет») выставлен в соответствии с Офертой на заключение лицензионного договора возмездного оказания услуг (https://unitx.pro/cloud-oferta).
        Оплачивая настоящий Счет, Вы подтверждаете, что полностью и безоговорочно согласились с условиями Оферты и Договора, заключенного между ООО "Открытые разработки" и Вами на условиях Оферты.
        </body>
        </html>
    `
}