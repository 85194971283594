import React, { useState, useEffect } from "react";

import {
    Grid,
    Col
} from "react-bootstrap";

import axios from 'axios';
import Card from "components/Card.jsx";
import {localtime, error_format, extractContent, prepareContentForBar} from 'utils';
import { update } from 'server';
import { toast } from 'react-toastify';
import ReadMsgDialog from 'components/Dialogs/ReadMsgDialog';

const Readmessages = ({ ...props }) =>  {
    const [messages, setMessages] = useState([]);
    const [dialog, setDialog] = useState(null);

    const read_msg = (text) =>
    {
      axios.post('/info' , {type: 'mailread', 'msg': text})
      .then(() => {update();})
      .catch((error) => {  toast.error(error_format(error)) })
    }

    const show_msg = (s) =>
    {
        setDialog( <ReadMsgDialog msg={s} hide={()=>{setDialog(null)}}/>);
        read_msg(s);
    }

    useEffect(() => {
        axios.get('/info', { params: { 'type': 'mail' } })
        .then((res) => {setMessages(JSON.parse(res.request.response));})
      });
    
    if (window.ReactNativeWebView)
    {
        window.ReactNativeWebView.postMessage( JSON.stringify({new_mail: 0, path: '/readmsg'}) );
    }

    const data = messages.slice(0).reverse().map(function(message, key)
      { 
        return (  <div key={key} onClick={() => show_msg(extractContent(message.msg))}>
                    <Card
                        content={<div>
                            <p className={message.new?undefined:"text-muted"}>
                                {localtime(message.ts).format('LLL')} 
                            <br/>
                                {prepareContentForBar(message.msg)}
                            </p>
                        </div>}
                    />
                    </div>
                )
      });

    
  return (
    <div className="wrapper">
      <div className={"main-panel"}>
        <div className="main-content">
            {dialog}
            <Grid fluid>
                <Col>
                {data}
                </Col>
            </Grid>
        </div>
      </div>
    </div>
  );
}

export default Readmessages;
