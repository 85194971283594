import React, { useState } from 'react';

import { localtime } from 'utils';
import { I18n } from 'I18n.js';
import { Table, Modal } from 'react-bootstrap';
import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'
import axios from 'axios';

const OfficeControlNowDialog = ({ ...props }) =>  {
  const { card } = props;

  const [table_body, setTable_body] = useState(null);
  const [show, setShow] = useState(false);

  const title = I18n.get('In the office ');
  
  const toggle = () => {
    if (!show) {getstate();}
    setShow(!show);
  }

  const getstate = () => {
    axios.get('/attributes', { params: {'id': card.id} } )
    .then((res) => {
      const attrs = JSON.parse(res.request.response);
      const tbl = [];
      Object.keys(attrs).forEach((key,i) => {
        if (key.startsWith('user'))
        { // sortit
          // scroll

          const idx = key.slice(4);
          if (attrs['stat'+idx]>0){
            tbl.push(
                <tr key={i}>
                <td>{attrs[key]}</td>
                <td>{localtime(attrs['lsts'+idx]).format('HH:mm (DD MMMM YYYY)')}</td>
                </tr>)
          }
        }
        
        
        if (tbl.length>0){
            setTable_body(<Table responsive>
                                <thead>
                                    <tr>
                                    <th>{I18n.get('Name')}</th>
                                    <th>{I18n.get('Time')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {tbl}
                                </tbody>
                            </Table>)
        }else {
            setTable_body(<p>{I18n.get('Nobody')}</p>)
        }
        
        
      });

    })
   }


    const footer =  <Modal.Footer>
                <Button simple onClick={toggle}> {I18n.get('Close')} </Button>
            </Modal.Footer>

  return (
        <>
           <Button bsSize="xs" round onClick={toggle}>
            {I18n.get('Now')}
          </Button>
          <BaseDialog show={show} onhide_cb={toggle} title={title} body={table_body} footer={footer} />
        </>
          );
               

}
export default OfficeControlNowDialog;