import React, { useState } from 'react';
import axios from 'axios';
import {error_format} from 'utils';
import { I18n } from 'I18n.js';
//import { update } from 'server';
import {
  ControlLabel,
  FormControl,
} from "react-bootstrap";

import Button from "components/CustomButton.jsx";
import BaseDialog from './BaseDialog'

const ShareDialog = ({ ...props }) => {
  const {alias, update, id } = props;

  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState('');
  const [user, setUser] = useState('');

  const toggle = () => {
    setShow(!show);
    setMsg('');
  }

  const apply = () =>
  {
    axios.post('/thing/share', { 'id': id, 'user': user})
    .then(() => { setMsg(I18n.get('Shared'));update(); })
    .catch((error) => { setMsg(error_format(error)); })
  }

  const title = I18n.get('Share') + " " + alias;
  const body =<form onSubmit={e => { e.preventDefault(); }}>
                    <br/>
                <ControlLabel>{I18n.get("Receiver's user e-mail")}</ControlLabel>
                <br/>
                <br/>
                <FormControl type="text" name="user" id="user" placeholder="e-mail" onChange={(e) => {setUser(e.target.value)} }/>
                <br/>
              </form>

  return (
    <>
      {(msg === '') &&<Button simple  bsStyle="success" onClick={toggle}> {I18n.get('Add')} </Button>}
      <BaseDialog show={show} title={title} body={body} onhide_cb={toggle} apply_cb={apply} msg={msg} />
    </>
  );
}

export default ShareDialog;